
import Vue from 'vue'
import BaseResource, { UserResource } from '@sigmacloud/sigma-client/dist/resources'
import AssignmentResource from '@sigmacloud/sigma-client/dist/resources/system/assignment'
import EmployeeResource from '@sigmacloud/sigma-client/dist/resources/system/employee'
import ErrorsMixins from '../mixins/ErrorsMixins'

export default Vue.extend({
    name: 'Autocomplete',

    mixins: [ErrorsMixins],

    props: {
        // Name of input field.  Not really necessary, but helpful if we use the form object down the road
        name: {
            required: true,
            type: String,
        },

        // Properties to display after a selection is made, e.g. name.
        displayProp: {
            required: false,
            type: String,
            default: 'name',
        },

        secondaryDisplayProp: {
            required: false,
            type: String,
        },

        // A list of css classes
        classes: {
            required: false,
            type: String,
            default: 'form-control',
        },

        // Force a name and do not use the autocomplete/lookup
        preSelectedValue: {
            required: false,
            type: [String, Number],
            default: '',
        },

        // Placeholder text for input
        placeholder: {
            required: false,
            type: String,
            default: '',
        },

        // To mark this field as required
        required: {
            required: false,
            type: Boolean,
            default: false,
        },

        // To mark this field as readonly/disabled
        readonly: {
            required: false,
            type: Boolean,
            default: false,
        },

        /*
        // Field to highlight if desired
        highlight: {
            required: false,
            type: Number,
            default: 0,
        },
        */

        // Used to identify the model.  Useful for changed fields
        dataModel: {
            type: String,
            required: false,
        },

        /* This is for a traditional array of options to be passed in
         *
         */
        // The list of options.  Should be an array of objects or a Rest Resource
        options: {
            required: false,
            type: Array,
            default() {
                return []
            },
        },

        // Properties of the options to search against, e.g. name, code, description
        searchProps: {
            required: false,
            type: Array,
            default() {
                return ['code', 'name', 'id']
            },
        },

        /* This is to use Rest Resource classes
         *
         */
        // Should be a Rest Resource
        resourceClass: {
            required: false,
            type: Function,
        },

        resolveManagers: {
            required: false,
            type: Array,
            default: function() {
                return []
            },
        },

        // id to select for rest resource by doing a lookup
        forceSelectId: {
            required: false,
            type: [String, Number],
        },

        /*
        // code to select for rest resource by doing a lookup
        forceSelectCode: {
            required: false,
            type: [ String, Number ],
        },
        */

        // Primary filter to be used with resourceClass, e.g. ?filter=
        primaryFilter: {
            required: false,
            type: String,
            default: undefined,
        },

        // Filter options that are not the primary filter.  Helps with limiting options, e.g. { type__in: 'POSTED', 'CLOSED' }
        filterOptions: {
            required: false,
            type: Object,
            default() {
                return {}
            },
        },

        // Number of characters to collect before querying API
        minKeywordLength: {
            required: false,
            type: Number,
            default: 1,
        },

        strictSearch: {
            required: false,
            type: Boolean,
            default: false,
        },

        ordering: {
            required: false,
            type: String,
            default: null,
        },
        filterName: {
            required: false,
            type: String,

            default: null,
        },
    },

    data() {
        return {
            selected: false, // If the user has made a selection
            showList: false, // Show the options list
            isError: false, // If the input or selected field is incorrect, show an error
            keyword: '', // The actual input field
            highlightedPosition: 0, // Current list item to highlight
            query: '', // Search sent to Rest Resource API
            loading: false,
            resources: [], // Resources retrieved from search
            debouncedInput: '',
            lastSearchTimestamp: undefined,
        }
    },

    watch: {
        keyword(newValue, oldValue) {
            if (newValue === this.preSelectedValue) return false // Forced display of input, no lookup
            if (newValue && newValue.length && newValue !== oldValue && newValue.length > this.minKeywordLength) {
                this.lookup()
            } else if (oldValue && oldValue.lenght && oldValue.length > this.minKeywordLength) {
                this.lookup(oldValue)
                // console.log(newValue, oldValue)
            }
        },

        // Handle changes made after mounting, e.g. resources loaded asynchronously
        preSelectedValue(newValue, oldValue) {
            if (newValue && newValue !== oldValue) {
                this.keyword = newValue
                this.debouncedInput = newValue
            }
        },

        // Handle changes made after mounting, e.g. resources loaded asynchronously
        async forceSelectId(newValue, oldValue) {
            if (newValue && newValue !== oldValue) {
                // console.log('ForceSelectId field changed: ' + newValue);
                try {
                    await this.detailById(newValue)
                } catch (error) {
                    this.$emit('message', error)
                }
            }
        },

        // optionMatches (newValue, oldValue){

        //     console.log(this.optionMatches)
        // },

        debouncedInput(newValue, oldValue) {
            if (newValue === this.preSelectedValue) {
                this.keyword = newValue
            }
            if (newValue !== oldValue) {
                let curTimestamp = Date.now()
                // console.log(curTimestamp)
                if (this.lastSearchTimestamp === undefined) {
                    this.lastSearchTimestamp = curTimestamp
                    this.keyword = this.debouncedInput
                } else if (curTimestamp - this.lastSearchTimestamp > 300) {
                    this.lastSearchTimestamp = curTimestamp
                    this.keyword = this.debouncedInput
                } else {
                    setTimeout(() => {
                        this.keyword = this.debouncedInput
                    }, 300)
                }
            } else {
                return
                // this.keyword = this.debouncedInput
            }
        },

        /*
        // Handle changes made after mounting, e.g. resources loaded asynchronously
        async forceSelectCode (newValue, oldValue) {
            if (newValue && newValue !== oldValue) {
                // console.log('ForceSelectCode field changed: ' + newValue);
                let result = await this.detailByCode(newValue)
                // let result = await this.detailById(newValue)
                // if (result) this.keyword = result.attributes[this.displayProp]
            }
        },
        */

        loading() {
            this.$emit('loading', this.loading)
        },
    },

    computed: {
        // Find items that match the list based on the input
        optionMatches(): Array<any> {
            const keyword = typeof this.keyword === 'string' ? this.keyword.replace(/[|()\[\]\\]/g, '') : undefined
            const pattern = new RegExp('^' + keyword, 'i') // Look for items that start with pattern
            const loosePattern = new RegExp(keyword, 'i') // Look for items that have the pattern anywhere

            // Now go through options list and search the list of properties against the patterns
            if (this.keyword && this.options && this.options.length) {
                let results

                if (this.strictSearch) {
                    // console.log("strict search")
                    let matches = this.options
                        .filter((option) => {
                            for (let prop of this.searchProps) {
                                if (option[prop].toLowerCase().contains(this.keyword.toLowerCase())) {
                                    return option
                                }
                            }
                        })
                        .sort(this.optionComp)
                    results = matches
                } else {
                    const matches = this.options
                        .filter((option) => {
                            for (const prop of this.searchProps) {
                                if (option && option.hasOwnProperty(prop) && option[prop] && option[prop].toString().match(pattern)) {
                                    return option
                                }
                            }
                        })
                        .sort(this.optionComp)

                    const looseMatches = this.options
                        .filter((option) => {
                            for (const prop of this.searchProps) {
                                if (option && option.hasOwnProperty(prop) && option[prop] && option[prop].toString().match(loosePattern)) {
                                    return option
                                }
                            }
                        })
                        .sort(this.optionComp)

                    // De-dupe the matches
                    results = matches.concat(looseMatches).filter((match, index, self) => self.findIndex((t) => t.id === match.id) === index)
                }

                return results
            } else if (keyword && this.resources) {
                let matches = this.resources.map((resource) => {
                    if (this.resolveManagers && this.resolveManagers.length > 0) {
                        // console.log(this.resolveManagers)
                        resource.resolveRelated({ managers: this.resolveManagers })
                    }

                    if (resource && resource.attributes) {
                        return {
                            name: resource.attributes.name || '',
                            code: resource.attributes.code || '',
                            number: resource.attributes.number || '',
                            id: resource.id || resource.uuid,
                            resource: resource,
                            attributes: resource.attributes,
                            related: resource.managers,
                        }
                    }
                })
                return matches
            } else {
                return []
            }
        },
    },

    methods: {
        // When something is typed into the input field, show the list and make sure to highlight the first match
        onInput(value: string): void {
            if (!value) {
                return
            } else {
                if (this.keyword && this.keyword.length) {
                    this.showList = true
                } else if (value) {
                    this.keyword = value
                    this.showList = true
                }
            }

            this.debouncedInput = value

            this.selected = false
            this.isError = false
            this.showList = !!this.keyword
            // this.highlightedPosition = this.highlight
        },

        // When leaving the input field, select the highlighted value and return to the parent component
        async onSelect(item: Event, option) {
            let resource = undefined
            if (item instanceof MouseEvent && option !== undefined) {
                resource = this.optionMatches.find((match) => {
                    return match.id === option.id
                })
            } else {
                resource = this.optionMatches[this.highlightedPosition]
            }
            // let { resource } = option

            if (this.debouncedInput && this.debouncedInput === this.preSelectedValue) {
                // Loaded value.  ignore
                return
            }
            if (!item || !item.target) {
                return
            }

            let choice = undefined
            if (resource !== undefined) {
                choice = resource
            } else {
                choice = this.optionMatches[this.highlightedPosition]
            }

            // Make sure it's a valid entry with a displayable property
            if (!this.debouncedInput && this.required) {
                this.isError = true
                this.$emit('error', '')
            } else if (!this.debouncedInput) {
                // Return empty if no value and tabbing through.
            } else if (choice && this.resources) {
                this.selected = true
                this.$emit(
                    'select',
                    this.resources.find((res) => res.id === choice.id)
                )
                this.debouncedInput = choice.resource.attributes[this.displayProp]

                if (this.secondaryDisplayProp) {
                    this.debouncedInput += ` - ${choice.resource.attributes[this.secondaryDisplayProp]}`
                }
            } else if (choice && choice.hasOwnProperty(this.displayProp)) {
                this.selected = true
                this.$emit('select', choice)
                this.debouncedInput = choice.resource.attributes[this.displayProp]
                if (this.secondaryDisplayProp) {
                    this.debouncedInput += ` - ${choice.resource.attributes[this.secondaryDisplayProp]}`
                }
            } else if (this.selected) {
                // Do nothing if already selected and tabbing through.
            } else {
                this.isError = true
                this.$emit('error', choice)
            }

            this.showList = false

            //The following block is to account for someone tabbing through inputs with default values
            if (!choice && (item as KeyboardEvent).key === 'Tab' && this.forceSelectId) {
                this.isError = false
                return true
            }

            if (choice && choice.resource && choice.resource instanceof AssignmentResource) {
                let lastName = await choice.resource.get('employee.last_name')
                let firstName = await choice.resource.get('employee.first_name')
                let occName = await choice.resource.get('occ_code.description')
                let selectionText = `${lastName}, ${firstName} - ${occName}`
                this.debouncedInput = selectionText
            } else if (choice && choice.resource && (choice.resource instanceof EmployeeResource || choice.resource instanceof UserResource)) {
                let lastName = await choice.resource.get('last_name')
                let firstName = await choice.resource.get('first_name')
                let tinLastFour = await choice.resource.get('tin_last_4')
                let selectionText = `${lastName}, ${firstName}`
                if (tinLastFour) {
                    selectionText += ` - ${tinLastFour}`
                }
                this.debouncedInput = selectionText
            }

            let self = this
            Vue.nextTick().then(() => {
                if (self.$refs.acInput) {
                    self.$refs.acInput.$el.focus()
                }
                return
            })
        },

        onKeyDown(event): void {
            let keyCode = event.which || event.keyCode
            if (keyCode === 13 || keyCode === 9) {
                this.onSelect(event) // Enter/Return
            } else if (keyCode === 38) {
                this.moveUp() // Up
            } else if (keyCode === 40) {
                this.moveDown() // Down
            } else if (keyCode === 192) {
                // Escape
                this.showList = false
                // this.params.api.stopEditing()
            } else if (keyCode === 8 && this.filterName == 'project') {
                //on remove project reset selected project
                this.$emit('reset')
            } else {
                // No action for now
            }

            event.stopPropagation()
        },

        // Move the highlighed item index up
        moveUp(): boolean {
            // Only scroll up if not past the last item on the list
            if (this.showList && this.optionMatches.length && this.optionMatches.length > 0 && this.highlightedPosition > 0) {
                this.highlightedPosition--

                // Else scroll to the bottom
            } else if (this.showList && this.optionMatches.length && this.optionMatches.length > 0 && this.highlightedPosition <= 0) {
                this.highlightedPosition = this.optionMatches.length - 1
            } else {
                return false
            }

            const element = 'list-items'
            this.$refs[element][this.highlightedPosition].scrollIntoView()
            this.$refs[element][this.highlightedPosition].focus()
            return true
        },

        // Move the highlighed item index down
        moveDown(): boolean {
            // Only scroll down if not past the last item on the list
            if (this.showList && this.optionMatches.length && this.highlightedPosition < this.optionMatches.length - 1) {
                this.highlightedPosition++

                // Else scroll to the top
            } else {
                this.highlightedPosition = 0
            }

            const element = 'list-items'
            this.$refs[element][this.highlightedPosition].scrollIntoView()
            this.$refs[element][this.highlightedPosition].focus()
            return true
        },

        // Compare options for sorting
        optionComp(a: any, b: any): number {
            let aFixed
            let bFixed

            if (a.name && b.name) {
                aFixed = a.name.toLowerCase()
                bFixed = b.name.toLowerCase()
            } else if (a.code && b.code) {
                aFixed = a.code.toLowerCase()
                bFixed = b.code.toLowerCase()
            } else {
                return a - b
            }

            if (aFixed > bFixed) {
                return 1
            } else if (aFixed < bFixed) {
                return -1
            } else {
                return 0
            }
        },

        // Rest resource lookup
        async lookup(oldValue) {
            let searchTerm
            if (oldValue) {
                searchTerm = oldValue
            } else {
                searchTerm = this.debouncedInput
            }
            let ResourceClass = (this.resourceClass as typeof BaseResource) || undefined
            this.loading = true
            if (searchTerm && this.primaryFilter) {
                let query = Object.assign({}, this.filterOptions)
                query[this.primaryFilter] = searchTerm
                try {
                    let result = await ResourceClass.filter(query)
                    this.resources = result.resources
                } catch (error) {
                    this.$emit('message', error)
                }
            } else if (searchTerm) {
                let query = Object.assign({}, this.filterOptions)
                query.search = searchTerm
                try {
                    let data = {}

                    if (this.filterName == 'invoice') {
                        if (searchTerm.length < 6) {
                            this.loading = false
                            return
                        }
                        data = Object.assign({}, this.filterOptions)
                    }
                    if (this.filterName == 'invoice') {
                        delete query.search
                        data = { number__icontains: searchTerm }
                    } else {
                        data = Object.assign(data, { ordering: this.ordering }, this.filterOptions)
                    }
                    let result = this.filterName == 'invoice' ? await ResourceClass.filter(data) : await ResourceClass.search(query.search, data)
                    this.resources = result.resources
                } catch (error) {
                    this.$emit('message', error)
                }
            } else {
                // console.log('no keyword')
                try {
                    let result = await ResourceClass.list()
                    if (this.resolveManagers && this.resolveManagers.length > 0) {
                        await result.resolveRelated({ managers: this.resolveManagers })
                    }
                    this.resources = result.resources
                } catch (error) {
                    this.$emit('message', error)
                }
            }

            this.loading = false
        },

        // Rest resource get detail
        async detailById(id: string | number): Promise<BaseResource> {
            let ResourceClass = (this.resourceClass as typeof BaseResource) || undefined

            if (id) {
                try {
                    let result = await ResourceClass.detail(id)
                    if (result) this.keyword = result.attributes[this.displayProp]
                    if (result) this.debouncedInput = result.attributes[this.displayProp]
                    if (result && this.secondaryDisplayProp) {
                        this.keyword += ` - ${result.attributes[this.secondaryDisplayProp]}`
                        this.debouncedInput += ` - ${result.attributes[this.secondaryDisplayProp]}`
                    }
                    this.$emit('select', result)
                    return result
                } catch (error) {
                    this.$emit('message', error)
                }
            } else {
                return null
            }
        },

        // Rest resource get detail
        async detailByCode(code: string | number): Promise<BaseResource> {
            let ResourceClass = (this.resourceClass as typeof BaseResource) || undefined

            if (code) {
                code = code as string
                try {
                    let result = await ResourceClass.filter({ query: { code__eq: code } })
                    if (result) this.keyword = result[0].attributes[this.displayProp]
                    if (result && this.secondaryDisplayProp) {
                        this.keyword += ` - ${result[0].attributes[this.secondaryDisplayProp]}`
                    }
                    this.$emit('select', result[0])
                    return result[0]
                } catch (error) {
                    this.$emit('message', error)
                }
            } else {
                return null
            }
        },

        clear() {
            this.debouncedInput = undefined
        },
    },

    async mounted() {
        if (this.preSelectedValue) {
            this.keyword = this.preSelectedValue
            this.debouncedInput = this.preSelectedValue
        } else if (this.forceSelectId) {
            this.keyword = this.forceSelectId
            try {
                const detail = await this.detailById(this.forceSelectId)
                this.$emit('set-initial-value', detail)
            } catch (error) {
                this.$emit('message', error)
            }
        }
    },
})

export interface AutocompleteResponseAttributes extends Record<string, any> {
    id: number
    name: string
    code?: string
    attributes?: any
}
