import Vue, { PluginObject } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import './assets/main.scss'
import VueClipboard from 'vue-clipboard2'
// import Sigma from '@sigmacloud/sigma-client'
// import SigmaPlugin from '@sigmacloud/sigma-client/dist/components/plugins/sigma'
//@ts-ignore
// let PO = SigmaPlugin as PluginObject<any>
Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueClipboard)
//@ts-ignore
// Vue.use(PO)

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app')
