import CustomPayrollDetailLineResource from './customPayrollDetailLineResource'
import customPayrollResource from './customPayrollResource'
import PayCodeResource from '@sigmacloud/sigma-client/dist/resources/system/paycode'

export default class TimeEditLine {
    attributes: TimeEditLineAttributes

    constructor(attributes?: TimeEditLineAttributes) {
        this.attributes = attributes || {}
    }

    set(name: string, value: any): void {
        // console.log(this.attributes)
        this.attributes[name] = value
    }

    // Convert this class into one or more lines ready to POST
    async toDetailLines(): Promise<CustomPayrollDetailLineResource[]> {
        let resources = []

        // Loop through 25 sets of possible paycodes to see if any values are there
        for (let i = 1; i <= 35; i++) {
            const hours_units = this.attributes['hours_units_' + i]
            const pay_code = this.attributes['pay_code_' + i]
            const amount = this.attributes['amount_' + i]
            let fee = this.attributes[`fee_${i}`]

            if ((hours_units && parseFloat(hours_units) > 0) || amount > 0) {
                // Create a new line
                let detailLine = new CustomPayrollDetailLineResource()

                // TODO Line numbers
                // detailLine.set('line_number', existingLineCount + i + 1)
                detailLine.set('transaction_id', this.attributes.transaction_id)
                detailLine.set('transaction', this.attributes.transaction_id)
                detailLine.set('assignment', this.attributes.assignment)
                detailLine.set('work_date', this.attributes.work_date)
                detailLine.set('employee', this.attributes.employee)
                detailLine.set('hourly_unit_rate', this.attributes.hourly_unit_rate)
                detailLine.set('occ_code', this.attributes.occ_code)
                if (this.attributes.scale_rate_decimal && this.attributes.scale_rate_decimal.length) {
                    detailLine.set('scale_rate_decimal', this.attributes.scale_rate_decimal)
                }
                if (pay_code && pay_code.id) {
                    detailLine.set('pay_code', pay_code.id)
                } else if (fee) {
                    detailLine.set('pay_code', null)
                    detailLine.set('fee', fee)
                    detailLine.set('fee_name', this.attributes.fee_name)
                    detailLine.set('status', this.attributes.status)
                    detailLine.set('override', this.attributes.override)
                }
                detailLine.set('type', this.attributes.type || 'PAY')
                detailLine.set('hours_units', hours_units)
                detailLine.set('amount_decimal', amount)
                detailLine.set('customer_budget_code', this.attributes.customer_budget_code)
                detailLine.set('customer_fringe_code', this.attributes.customer_fringe_code)
                detailLine.set('customer_hf_code', this.attributes.customer_hf_code)
                detailLine.set('customer_episode', this.attributes.customer_episode)
                detailLine.set('customer_series', this.attributes.customer_series)
                detailLine.set('customer_location', this.attributes.customer_location)
                detailLine.set('customer_set', this.attributes.customer_set)
                detailLine.set('customer_ff1', this.attributes.customer_ff1)
                detailLine.set('customer_ff2', this.attributes.customer_ff2)
                detailLine.set('customer_ff3', this.attributes.customer_ff3)
                detailLine.set('customer_ff4', this.attributes.customer_ff4)
                detailLine.set('pension_hours_units', this.attributes.pension_hours_units)
                await detailLine.resolveRelated({ managers: ['assignment', 'employee', 'pay_code'] })
                detailLine.set('meta_name', detailLine.get('employee.last_name') + ', ' + detailLine.get('employee.first_name'))
                detailLine.set('pay_code_name', detailLine.get('pay_code.code') + ' - ' + detailLine.get('pay_code.name'))
                detailLine.set('work_address', this.attributes.work_address)
                resources.push(detailLine)
            } else if (fee) {
                let detailLine = new CustomPayrollDetailLineResource()

                // TODO Line numbers
                // detailLine.set('line_number', existingLineCount + i + 1)
                detailLine.set('transaction_id', this.attributes.transaction_id)
                detailLine.set('transaction', this.attributes.transaction_id)
                detailLine.set('assignment', this.attributes.assignment)
                detailLine.set('work_date', this.attributes.work_date)
                detailLine.set('employee', this.attributes.employee)
                detailLine.set('hourly_unit_rate', this.attributes.hourly_unit_rate)
                detailLine.set('occ_code', this.attributes.occ_code)
                detailLine.set('pay_code', pay_code && pay_code.id ? pay_code.id : null)
                detailLine.set('fee', fee)
                detailLine.set('fee_name', this.attributes.fee_name)
                detailLine.set('type', this.attributes.type)
                detailLine.set('hours_units', hours_units)
                detailLine.set('amount_decimal', amount)
                detailLine.set('customer_budget_code', this.attributes.customer_budget_code)
                detailLine.set('customer_fringe_code', this.attributes.customer_fringe_code)
                detailLine.set('customer_hf_code', this.attributes.customer_hf_code)
                detailLine.set('customer_episode', this.attributes.customer_episode)
                detailLine.set('customer_series', this.attributes.customer_series)
                detailLine.set('customer_location', this.attributes.customer_location)
                detailLine.set('customer_set', this.attributes.customer_set)
                detailLine.set('customer_ff1', this.attributes.customer_ff1)
                detailLine.set('customer_ff2', this.attributes.customer_ff2)
                detailLine.set('customer_ff3', this.attributes.customer_ff3)
                detailLine.set('customer_ff4', this.attributes.customer_ff4)
                detailLine.set('status', this.attributes.status)
                detailLine.set('work_address', this.attributes.work_address)
                detailLine.set('pension_hours_units', this.attributes.pension_hours_units)
                await detailLine.resolveRelated({ managers: ['assignment', 'employee', 'pay_code'] })
                detailLine.set('meta_name', detailLine.get('employee.last_name') + ', ' + detailLine.get('employee.first_name'))
                // detailLine.set('pay_code_name', detailLine.get('pay_code.code') + ' - ' + detailLine.get('pay_code.name'))
                resources.push(detailLine)
            }
        }

        return resources
    }
}

export interface TimeEditLineAttributes extends Record<string, any> {
    work_date?: string
    transaction_id?: number
    transaction?: number
    assignment?: number
    employee?: number
    type?: string
    pay_code_name?: string
    meta_name?: string
    description?: string
    hourly_unit_rate?: string
    customer_budget_code?: string
    customer_fringe_code?: string
    customer_hf_code?: string
    customer_episode?: string
    customer_series?: string
    customer_location?: string
    customer_set?: string
    customer_ff1?: string
    customer_ff2?: string
    customer_ff3?: string
    customer_ff4?: string
    fee?: string | number
    fee_name?: string
    pension_hours_units?: string
    scale_rate_decimal?: string

    pay_code_1?: PayCodeResource
    factor_1?: number
    hours_units_1?: number
    amount_1?: string
    pay_code_2?: PayCodeResource
    factor_2?: number
    hours_units_2?: number
    amount_2?: string
    pay_code_3?: PayCodeResource
    factor_3?: number
    amount_3?: string
    hours_units_3?: number
    pay_code_4?: PayCodeResource
    factor_4?: number
    hours_units_4?: number
    amount_4?: string
    amount_decimal?: number
    pay_code_5?: PayCodeResource
    factor_5?: number
    hours_units_5?: number
    amount_5?: string
    pay_code_6?: PayCodeResource
    factor_6?: number
    hours_units_6?: number
    amount_6?: string
    pay_code_7?: PayCodeResource
    factor_7?: number
    hours_units_7?: number
    amount_7?: string
    pay_code_8?: PayCodeResource
    factor_8?: number
    hours_units_8?: number
    amount_8?: string
    pay_code_9?: PayCodeResource
    factor_9?: number
    hours_units_9?: number
    amount_9?: string
    pay_code_10?: PayCodeResource
    factor_10?: number
    hours_units_10?: number
    amount_10?: string
    pay_code_11?: PayCodeResource
    factor_11?: number
    hours_units_11?: number
    amount_11?: string
    pay_code_12?: PayCodeResource
    factor_12?: number
    hours_units_12?: number
    amount_12?: string
    pay_code_13?: PayCodeResource
    factor_13?: number
    hours_units_13?: number
    amount_13?: string
    pay_code_14?: PayCodeResource
    factor_14?: number
    hours_units_14?: number
    amount_14?: string
    pay_code_15?: PayCodeResource
    factor_15?: number
    hours_units_15?: number
    amount_15?: string
    pay_code_16?: PayCodeResource
    factor_16?: number
    hours_units_16?: number
    amount_16?: string
    pay_code_17?: PayCodeResource
    factor_17?: number
    hours_units_17?: number
    amount_17?: string
    pay_code_18?: PayCodeResource
    factor_18?: number
    hours_units_18?: number
    amount_18?: string
    pay_code_19?: PayCodeResource
    factor_19?: number
    hours_units_19?: number
    amount_19?: string
    pay_code_20?: PayCodeResource
    factor_20?: number
    hours_units_20?: number
    amount_20?: string
    pay_code_21?: PayCodeResource
    factor_21?: number
    hours_units_21?: number
    amount_21?: string
    pay_code_22?: PayCodeResource
    factor_22?: number
    hours_units_22?: number
    amount_22?: string
    pay_code_23?: PayCodeResource
    factor_23?: number
    hours_units_23?: number
    amount_23?: string
    pay_code_24?: PayCodeResource
    factor_24?: number
    hours_units_24?: number
    amount_24?: string
    pay_code_25?: PayCodeResource
    factor_25?: number
    hours_units_25?: number
    amount_25?: string

    fee_26?: number
    fee_type_26?: string
    amount_26?: string
    fee_27?: number
    fee_type_27?: string
    amount_27?: string
    fee_28?: number
    fee_type_28?: string
    amount_28?: string
    fee_29?: number
    fee_type_29?: string
    amount_29?: string
    fee_30?: number
    fee_type_30?: string
    amount_30?: string
    fee_31?: number
    fee_type_301?: string
    amount_31?: string
    fee_32?: number
    fee_type_32?: string
    amount_32?: string
    fee_33?: number
    fee_type_33?: string
    amount_33?: string
    fee_34?: number
    fee_type_34?: string
    amount_34?: string
    fee_35?: number
    fee_type_35?: string
    amount_35?: string

    work_address?: number
    occ_code?: number
    account?: string
    bank?: string
    currency?: number
}
