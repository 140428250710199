<script>
import Vue from 'vue'
import AssignmentResource from '@sigmacloud/sigma-client/dist/resources/system/assignment'
import AddressResource from '@sigmacloud/sigma-client/dist/resources/system/address'
import ProjectResource from '@sigmacloud/sigma-client/dist/resources/system/project'
import SigmaRelatedManager from '@sigmacloud/sigma-client/dist/resources/related'
import ErrorsMixins from '../mixins/ErrorsMixins'

export default Vue.extend({
    props: {
        options: {
            required: false,
            type: Array,
        },
    },
    mixins: [ErrorsMixins],
    data() {
        return {
            key: 1,
            selection: undefined,
            selectOptions: [],
            timeEditLine: undefined,
            assignmentRes: undefined,
            projectMgr: undefined,
            projectResource: undefined,
            workAddresses: undefined,
            value: undefined,
            isAggregatedValue: false,
            editable: true,
        }
    },
    watch: {
        selection(newValue, oldValue) {
            this.value = newValue
        },
    },
    async mounted() {
        if (this.params.node.aggData && this.params.node.aggData.work_address) {
            this.value = this.params.node.aggData.work_address
            try {
                let workAddressResource = await AddressResource.detail(this.value)
                this.workAddresses = { resources: [workAddressResource] }
                await this.setupSelectOptions()
                this.selection = this.value
                this.isAggregatedValue = true
                this.key++
            } catch (error) {
                this.$emit('message', error)
            }
        } else {
            this.timeEditLine = this.params.node.data
            try {
                await this.getAssignment()
                if (this.assignmentRes) {
                    await this.getProject()
                    if (this.projectResource) {
                        await this.getWorkAddresses()
                        if (this.workAddresses) {
                            await this.setupSelectOptions()
                        }
                    }
                }
            } catch (error) {
                this.$emit('message', error)
            }
            this.selection = this.params.value
            this.key++
        }

        this.setEditable()
    },
    methods: {
        onChange() {
            let data = this.params.node.data
            data.attributes.work_address = this.selection
            this.params.node.setData(data)
            this.$emit('input', this.selection)
        },
        async getAssignment() {
            let assignmentId = this.timeEditLine.attributes.assignment
            if (assignmentId && !this.assignmentRes) {
                try {
                    this.assignmentRes = await AssignmentResource.detail(assignmentId)
                } catch (error) {
                    this.$emit('message', error)
                }
            }
        },
        async getProject() {
            if (!this.projectResource) {
                this.projectMgr = this.assignmentRes.get('project')
                if (!this.projectMgr.attributes && !this.projectMgr.resolved) {
                    // This is an unresolved manager
                    try {
                        await this.projectMgr.resolve()
                        this.projectResource = this.projectMgr.resource
                    } catch (error) {
                        this.$emit('message', error)
                    }
                } else if (!this.projectMgr.attributes && this.projectMgr.resolved) {
                    // This is a resolved manager
                    this.projectResource = this.projectMgr.resource
                } else if (this.projectMgr.attributes) {
                    // This is a resource, not a manager
                    this.projectResource = this.projectMgr
                }
            }
        },
        async getWorkAddresses() {
            if (!this.workAddresses) {
                this.workAddresses = this.projectResource.get('work_addresses')
                try {
                    await this.workAddresses.resolve()
                } catch (error) {
                    this.$emit('message', error)
                }
            }
        },
        async setupSelectOptions() {
            let idMap = this.workAddresses.resources.map((addressResource) => addressResource.id)
            let addressResources = [...this.workAddresses.resources]

            if (this.params.node && this.params.node.data) {
                let line = this.params.node.data
                const lineWorkAddressResource = await AddressResource.detail(line.attributes.work_address)
                if (!idMap.includes(lineWorkAddressResource.id)) {
                    addressResources.push(lineWorkAddressResource)
                }
            }

            for (let address of addressResources) {
                try {
                    await address.resolveRelated({ managers: ['city', 'state'] })
                    if (address.managers.city.value || address.managers.state.value) {
                        this.selectOptions.push({
                            value: address.id,
                            text: `${address.attributes.line1}, ${address.get('city')}, ${address.get('state.code')} ${address.attributes.postal}`,
                        })
                    } else {
                        this.selectOptions.push({
                            value: address.id,
                            text: `Address from calculations`,
                        })
                    }
                } catch (error) {
                    this.$emit('message', error)
                }
            }
        },
        setEditable() {
            const editableColDef = this.params.colDef.editable

            if (editableColDef instanceof Function) {
                this.editable = editableColDef()
            } else if (editableColDef === false) {
                this.editable = false
            }
        },
    },
})
</script>
<template>
    <div>
        <b-form-select size="sm" :options="selectOptions" :disabled="isAggregatedValue || !editable" v-model="selection" @change="onChange" @select="onChange"></b-form-select>
    </div>
</template>
