<script>
import Vue from 'vue'

export default Vue.extend({
    data() {
        return {
            editable: true,
        }
    },
    methods: {
        checkedHandler(event) {
            let checked = event.target.checked
            let colId = this.params.column.colId
            this.params.node.setDataValue(colId, checked)
        },
        setEditable() {
            const editableColDef = this.params.colDef.editable

            if (editableColDef instanceof Function) {
                this.editable = editableColDef()
            } else if (editableColDef === false) {
                this.editable = false
            }
        },
    },
    mounted() {
        this.setEditable()
    },
})
</script>
<template>
    <input type="checkbox" @click="checkedHandler($event)" :checked="params.value" :disabled="!editable" />
</template>
