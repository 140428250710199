
import Vue from 'vue'
import { SigmaAPIClient } from '@sigmacloud/sigma-client/dist/resources/client'
import BaseResource from '@sigmacloud/sigma-client/dist/resources'
import UserResource from '@sigmacloud/sigma-client/dist/resources/user'
import EmployeeResource from '@sigmacloud/sigma-client/dist/resources/system/employee'
import ProjectResource from '../../node_modules/@sigmacloud/sigma-client/dist/resources/system/project'
import ClientResource from '../../node_modules/@sigmacloud/sigma-client/dist/resources/system/client'
import ReportsMixins from '../mixins/ReportsMixins'
import Autocomplete from './Autocomplete.vue'
import ErrorsMixins from '../mixins/ErrorsMixins'
import CustomPayrollResource from '../resources/customPayrollResource'
import CompanyAddress from './CompanyAddress.vue'
import { differenceInHours, differenceInCalendarDays, differenceInMinutes } from 'date-fns'

export default Vue.extend({
    name: 'ResourceList',
    components: {
        Autocomplete,
    },
    mixins: [ReportsMixins, ErrorsMixins],
    data() {
        return {
            query: '',
            resources: [],
            payrollResources: [],
            prAttributes: [],
            payrollListResource: undefined,
            instance: undefined,
            tableLoading: undefined,
            tableFields: [
                {
                    key: 'id',
                    sortable: true,
                },
                {
                    key: 'number',
                    label: 'Invoice #',
                    sortable: true,
                },
                {
                    key: 'client',
                    sortable: true,
                },
                {
                    key: 'project',
                    sortable: true,
                },
                {
                    key: 'pay_period_end_date',
                    sortable: true,
                },
                {
                    key: 'date_due',
                    sortable: true,
                },
                {
                    key: 'check_date',
                    sortable: true,
                },
                // {
                //     key: 'description',
                //     sortable: true,
                // },
                {
                    key: 'status',
                    sortable: true,
                },
                {
                    key: 'coordinator_name',
                    label: 'Payroll Coordinator',
                    sortable: true,
                },
                'notes',
                'payroll_status',
                'go_to_payroll',
            ],
            statusChoices: ['DRAFT', 'OPEN', 'AUDITED', { text: '   ', disabled: true }, 'POSTED', { text: '   ', disabled: true }, 'CLOSED', 'VOIDED', 'DELETED'],
            unionChoices: [''],
            loading: false,
            table: 1,
            currentPage: 1,
            rows: 0,
            perPage: 50,
            paginationKey: 1,
            autocompleteResults: undefined,
            filterProps: {
                endDateFrom: undefined,
                endDateTo: undefined,
                project: undefined,
                union: {
                    union: true,
                    nonUnion: true,
                },
                status: {
                    draft: true,
                    open: true,
                    audited: true,
                    posted: false,
                    closed: false,
                },
                checkDateFrom: undefined,
                checkDateTo: undefined,
                dueDateFrom: undefined,
                dueDateTo: undefined,
                idNumFrom: undefined,
                idNumTo: undefined,
                payrollCoordinator: undefined,
                invoiceId: undefined,
                isDelayed: true,
                isOnTime: false,
            },
            filterString: undefined,
            reportsOptions: [],
            showAddressModal: false,
            payrollResouces: [],
        }
    },
    props: {
        resourceClass: Function,
        instanceGetter: Function,
        tokenGetter: Function,
    },
    computed: {
        resourceClasses() {
            return {
                CustomPayrollResource,
                ProjectResource,
                ClientResource,
                EmployeeResource,
                UserResource,
            }
        },
    },
    methods: {
        async search() {
            this.loading = true
            try {
                let ResourceClass = this.$props.resourceClass as typeof BaseResource

                if (this.$data.query) {
                    let result = await ResourceClass.search(this.$data.query)
                    this.$data.resources = result.resources
                } else {
                    let result = await ResourceClass.list()
                    this.$data.resources = result.resources
                }
                this.loading = false
            } catch (error) {
                this.$emit('message', error)
                this.loading = false
            }
        },

        goToPayroll(item) {
            let data = Object.assign({}, item)
            this.$router.push({ path: `/payroll/${data.id}` })
        },

        autocompleteGoToPayroll(item) {
            this.$router.push({ path: `/payroll/${item.id}` })
        },

        setDateFilter(name, value) {
            this.filterProps[name] = value
        },

        setProjectFilter(event) {
            this.filterProps.project = event.id
        },

        setClientFilter(event) {
            this.filterProps.client = event.id
        },
        setPayrollCoordinatorFilter(event) {
            this.filterProps.payrollCoordinator = event.id
        },

        async resetFilterValues() {
            this.filterProps = {
                endDateFrom: undefined,
                endDateTo: undefined,
                project: undefined,
                union: {
                    union: true,
                    nonUnion: true,
                },
                client: undefined,
                status: {
                    draft: false,
                    open: false,
                    audited: false,
                    posted: false,
                    closed: false,
                    voided: false,
                },
                checkDateFrom: undefined,
                checkDateTo: undefined,
                dueDateFrom: undefined,
                dueDateTo: undefined,
                idNumFrom: undefined,
                idNumTo: undefined,
            }
            this.$refs['project-autocomplete'].debouncedInput = ''
            this.$refs['client-autocomplete'].debouncedInput = ''
            try {
                this.loading = true
                await this.filterSearchResults()
                this.loading = false
            } catch (error) {
                this.$emit('message', error)
                this.loading = false
            }
        },

        async filterSearchResults(pageNum) {
            let statusString = ''
            let activeFilters = {}
            let statusKeys = Object.keys(this.filterProps.status)
            for (let key of statusKeys) {
                if (this.filterProps.status[key]) {
                    statusString += `${key.toUpperCase()},`
                }
            }
            statusString = statusString.substring(0, statusString.length - 1)
            if (statusString.length > 0) {
                activeFilters['status__in'] = statusString
            }
            let unionString = undefined
            if (!this.filterProps.union.union && this.filterProps.union.nonUnion) {
                // Show only non-union payrolls
                unionString = false
            } else if (this.filterProps.union.union && !this.filterProps.union.nonUnion) {
                // Show only union payrolls
                unionString = true
            }
            if (unionString !== undefined) {
                activeFilters['is_union'] = unionString
            }
            if (this.filterProps.project) {
                activeFilters['project'] = this.filterProps.project
            }
            if (this.filterProps.client) {
                activeFilters['project__client'] = this.filterProps.client
            }
            if (this.filterProps.checkDateFrom) {
                activeFilters[`check_date__gte`] = this.filterProps.checkDateFrom
            }
            if (this.filterProps.checkDateTo) {
                activeFilters['check_date__lte'] = this.filterProps.checkDateTo
            }
            if (this.filterProps.dueDateFrom) {
                activeFilters['due_date__gte'] = this.filterProps.dueDateFrom
            }
            if (this.filterProps.dueDateTo) {
                activeFilters['due_date__lte'] = this.filterProps.dueDateTo
            }
            if (this.filterProps.endDateFrom) {
                activeFilters['pay_period_end_date__gte'] = this.filterProps.endDateFrom
            }
            if (this.filterProps.endDateTo) {
                activeFilters['pay_period_end_date__lte'] = this.filterProps.endDateTo
            }
            if (this.filterProps.idNumFrom) {
                activeFilters['id__gte'] = this.filterProps.idNumFrom
            }
            if (this.filterProps.idNumTo) {
                activeFilters['id__lte'] = this.filterProps.idNumTo
            }
            if (this.filterProps.invoiceId) {
                activeFilters['number'] = this.filterProps.invoiceId
            }
            if (this.filterProps.payrollCoordinator) {
                activeFilters['payroll_coordinator'] = this.filterProps.payrollCoordinator
            }

            activeFilters['page_size'] = this.perPage
            if (pageNum && typeof pageNum === 'number') {
                activeFilters['page'] = pageNum
                this.currentPage = pageNum
            } else {
                this.currentPage = 1
            }
            this.loading = true

            if (this.filterProps.isDelayed || this.filterProps.OnTime) {
                activeFilters['ordering'] = `${!this.filterProps.isDelayed ? '-' : ''}date_due`
            }

            try {
                let results = await CustomPayrollResource.filter(activeFilters)
                await this.setPrTableData(results.resources)
                this.payrollResources = results.resources
                this.rows = results.count
            } catch (error) {
                this.$emit('message', error)
                this.loading = false
            } finally {
                this.paginationKey++
            }
        },

        async filterTableData(searchTerm) {
            this.loading = true
            try {
                let results = await CustomPayrollResource.search(searchTerm, { page_size: this.perPage })
                this.payrollResouces = results.resources
                await this.setPrTableData(results.resources)
                this.rows = results.count
            } catch (error) {
                this.$emit('message', error)
                this.loading = false
            }
        },

        async setPrTableData(prPage) {
            this.loading = true
            let prAttrs = prPage.map((e) => e.attributes || e)
            this.prAttributes = prAttrs
            this.loading = false
            this.table++

            const getDueIn = (data) => {
                let _thirdDate = new Date(data.expected_complete_by)
                let differenceHrs = differenceInHours(_thirdDate, new Date())
                let differenceMins = differenceInMinutes(_thirdDate, new Date())
                differenceMins = differenceMins % 60
                let diffDays = Math.trunc(differenceHrs / 24)
                differenceHrs = differenceHrs % 24

                if (diffDays == 0) {
                    data._rowVariant = 'warning'
                    data.payroll_status = `Due in ${differenceHrs} Hrs`
                }
            }

            const getDelayIn = (data) => {
                let _thirdDate = new Date(data.expected_complete_by)
                let differenceMins = differenceInMinutes(new Date(), _thirdDate)
                let differenceHrs = differenceInHours(new Date(), _thirdDate)
                differenceMins = differenceMins % 60
                let diffDays = Math.trunc(differenceHrs / 24)
                differenceHrs = differenceHrs % 24

                let payroll_status = `Delay by `

                if (diffDays > 0) {
                    payroll_status += `${diffDays} Day${diffDays > 1 ? 's' : ''} `
                    console.log(differenceHrs)
                }
                if (differenceHrs > 0) {
                    payroll_status += `${differenceHrs} Hrs `
                }
                if (differenceMins > 0) {
                    payroll_status += `${differenceMins} Mins`
                }

                data.payroll_status = payroll_status
                data._rowVariant = 'danger'
            }

            try {
                for (let prAttr of prAttrs) {
                    await this.getNotes(prAttr)
                    await this.getPayrollHistory(prAttr)
                    let project = await ProjectResource.detail(prAttr.project)
                    prAttr.project_name = `${project.attributes.number} - ${project.attributes.name}`

                    let ccode = await project.resolveAttribute('client.code')
                    let cname = await project.resolveAttribute('client.name')
                    if (prAttr.payroll_coordinator) {
                        let user = await UserResource.detail(prAttr.payroll_coordinator)
                        prAttr.coordinator_name = `${user.attributes.last_name}, ${user.attributes.first_name}`
                    }
                    prAttr.client = `${ccode} - ${cname}`

                    let _thirdDate = new Date(prAttr.expected_complete_by)

                    let differenceMins = differenceInMinutes(_thirdDate, new Date())

                    prAttr.payroll_status = 'On Time'

                    differenceMins > 0 ? getDueIn(prAttr) : getDelayIn(prAttr)

                    if (prAttr.status == 'POSTED' && prAttr.payroll_status != 'On Time') {
                        prAttr._rowVariant = ''
                    }
                }
                this.prAttributes = prAttrs
                return prAttrs
            } catch (error) {
                this.$emit('message', error)
                this.loading = false
            } finally {
                this.table++
            }
        },

        async getNotes(payroll) {
            if (payroll.notes) {
                return false
            }
            let notesResource = BaseResource.wrap(`/prs/${payroll.id}/notes`)
            let notesResponse = await notesResource.get()
            let { data }: any = notesResponse
            let { results } = data

            let index = this.prAttributes.findIndex((e) => e.id === payroll.id)
            if (index > -1) {
                this.prAttributes[index].notes = results
            }
            this.table++
        },

        async getPayrollHistory(payroll) {
            if (payroll.history) {
                return
            }
            let historyResource = BaseResource.wrap(`/prs/${payroll.id}/history`)
            let historyResponse = await historyResource.get()
            let { data }: any = historyResponse
            let { results } = data

            let index = this.prAttributes.findIndex((e) => e.id === payroll.id)
            if (index > -1) {
                this.prAttributes[index].history = results
                    .filter((e) => e.change_message.includes('status'))
                    .map((e) => {
                        return {
                            obj: JSON.parse(e.change_message.replace('Changed', '').trim()),
                            date: e.action_time,
                        }
                    })
                    .map((e) => {
                        return {
                            message: e.obj.previous.status ? `${e.obj.previous.status} to ${e.obj.current.status}` : e.obj.current.status,
                            date: e.date,
                        }
                    })
            }

            this.table++
        },
    },

    watch: {
        filterString() {
            this.filterTableData(this.filterString)
        },
        prAttributes() {
            this.table++
        },
    },

    created() {
        let instance = this.$props.instanceGetter()
        this.instance = instance
        let token = this.$props.tokenGetter()
        BaseResource.client = new SigmaAPIClient(instance, token)
    },

    async beforeMount() {
        for (let report of this.reports) {
            // this.reportsOptions.push(report.name)
            if (report.name !== 'Print Paychecks') {
                this.reportsOptions.push(report.name)
            }
        }
    },
    async mounted() {
        await this.filterSearchResults()
    },
})
