<script>
import Vue from 'vue'
import PayCodeResource from '@sigmacloud/sigma-client/dist/resources/system/paycode'
import Autocomplete from '../components/Autocomplete.vue'

export default Vue.extend({
    components: {
        Autocomplete,
    },
    data() {
        return {
            PayCodeResource,
        }
    },
    async mounted() {
        await this.$nextTick()
        this.$refs.autocomplete.$refs.acInput.focus()
    },
    methods: {
        isPopup() {
            return true
        },

        getValue() {
            return ''
        },

        updatePayCodeAndAmount(event) {
            const data = this.params.node.data
            const { id, code, description, factor } = event.attributes

            const oldFactor = parseFloat(data.attributes.factor).toFixed(4)

            data.attributes.pay_code = id
            data.attributes.pay_code_name = `${code}: ${description}`
            data.attributes.factor = factor

            const newAmount = (parseFloat(data.attributes.amount_decimal) * (factor / oldFactor)).toFixed(2)

            if (newAmount > 0) data.attributes.amount_decimal = newAmount
            this.params.node.setData(data)

            this.focusCellAfterUpdate()
        },
        focusCellAfterUpdate() {
            this.params.api.tabToNextCell()
            this.params.api.tabToPreviousCell()
        },
    },
})
</script>

<template>
    <autocomplete ref="autocomplete" class="paycode-autocomplete" name="`pay_code_editor`" @select="updatePayCodeAndAmount($event)" :resource-class="PayCodeResource" display-prop="code" secondary-display-prop="name">
        <template slot="item" slot-scope="option">
            <p>Pay code : {{ `${option.code}: ${option.itemname}` }}</p>
        </template>
    </autocomplete>
</template>

<style lang="scss">
.paycode-autocomplete input {
    height: 24px;
    font-size: 0.75rem;
}
</style>
