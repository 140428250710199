
import Vue from 'vue'
import { AgGridVue } from 'ag-grid-vue'
import { GridReadyEvent, FirstDataRenderedEvent, CellValueChangedEvent } from '../../node_modules/ag-grid-community/dist/lib/events'

import 'ag-grid-enterprise'
import { LicenseManager } from 'ag-grid-enterprise'
LicenseManager.setLicenseKey('CompanyName=Revolution Payroll,LicensedGroup=ProBooks,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-021824,ExpiryDate=4_December_2022_[v2]_MTY3MDExMjAwMDAwMA==0723da76405e8e4969df68bfe317209f')
import Autocomplete from '../components/Autocomplete.vue'

// Simga/Custom Resources
import PayrollResource from '../resources/customPayrollResource'
import CustomPayrollDetailLineResource from '../resources/customPayrollDetailLineResource'
import AssignmentResource from '@sigmacloud/sigma-client/dist/resources/system/assignment'
import PayCodeResource from '@sigmacloud/sigma-client/dist/resources/system/paycode'
import FeeResource from '@sigmacloud/sigma-client/dist/resources/system/fee'
import AccountResource from '@sigmacloud/sigma-client/dist/resources/system/account'
import TimeEditLine from '../resources/TimeEditLine'
import AgGridColumnMixins from '../mixins/AgGridColumns'
import DateMixins from '../mixins/DateMixins'
import ErrorsMixins from '../mixins/ErrorsMixins'
import { ProjectResource } from '@sigmacloud/sigma-client/dist/resources'

export default AgGridColumnMixins.extend({
    name: 'TimeEdit',

    components: {
        AgGridVue,
        Autocomplete,
    },

    mixins: [AgGridColumnMixins, DateMixins, ErrorsMixins],

    props: {
        payrollResource: {
            type: PayrollResource,
            required: false,
        },
        payrollDetailLineResource: {
            type: Array,
            required: false,
        },
        projectResourceAsPassed: {
            type: ProjectResource,
            required: false,
        },
    },

    data() {
        return {
            msg: '',
            agGridKeyVal: 0,
            loaded: false,
            loading: false,
            numInitialRows: 7,
            assignmentResource: undefined,
            projectResource: undefined,
            projectWorkAddress: undefined,
            numPayCodes: 25,
            numPayCodesDisplaying: 3,
            pay_code_1: undefined,
            pay_code_2: undefined,
            pay_code_3: undefined,
            pay_code_4: undefined,
            pay_code_5: undefined,
            pay_code_6: undefined,
            pay_code_7: undefined,
            pay_code_8: undefined,
            pay_code_9: undefined,
            pay_code_10: undefined,
            pay_code_11: undefined,
            pay_code_12: undefined,
            pay_code_13: undefined,
            pay_code_14: undefined,
            pay_code_15: undefined,
            pay_code_16: undefined,
            pay_code_17: undefined,
            pay_code_18: undefined,
            pay_code_19: undefined,
            pay_code_20: undefined,
            pay_code_21: undefined,
            pay_code_22: undefined,
            pay_code_23: undefined,
            pay_code_24: undefined,
            pay_code_25: undefined,
            pc22: {
                hf: undefined,
                fringe: undefined,
                account: undefined,
                amount: undefined,
                series: undefined,
                location: undefined,
                set: undefined,
                ff1: undefined,
                ff2: undefined,
            },
            pc23: {
                hf: undefined,
                fringe: undefined,
                account: undefined,
                amount: undefined,
                series: undefined,
                location: undefined,
                set: undefined,
                ff1: undefined,
                ff2: undefined,
            },
            pc24: {
                hf: undefined,
                fringe: undefined,
                account: undefined,
                amount: undefined,
                series: undefined,
                location: undefined,
                set: undefined,
                ff1: undefined,
                ff2: undefined,
            },
            pc25: {
                hf: undefined,
                fringe: undefined,
                account: undefined,
                amount: undefined,
                series: undefined,
                location: undefined,
                set: undefined,
                ff1: undefined,
                ff2: undefined,
            },
            displayAssignmentUsedAlert: false,
            potentialAssignment: undefined,
            defaultPayCodes: ['1', '6', '13'],
            newEntryLines: [],
            columnsSet: false,
            exporting: false,
            numFees: 1,
            feeData: [],
            feeTypeChoices: ['PAY', 'OFFSET', 'EEFEE', 'ERFEE', 'ACTUALFEE', 'ACCRUAL', 'DEDUCTION', 'ERTAX', 'EETAX', 'GARNISHMENT'],
            feeKey: 0,
            production: true,

            // Ag-Grid options
            columnDefs: [],
            // frameworkComponents: undefined,
            rowData: [],
            gridApi: null,
            columnApi: null,
            scope: this,
            gridOptions: {
                enableFillHandle: true,
                undoRedoCellEditing: true,
                singleClickEdit: false,
                onGridReady: (params: GridReadyEvent) => {
                    this.$data.scope.onGridReady(params)
                },
                onFirstDataRendered: (params: FirstDataRenderedEvent) => {
                    this.$data.scope.onFirstDataRendered(params)
                },
                onCellValueChanged: (params: CellValueChangedEvent) => {
                    this.$data.scope.onCellValueChanged(params)
                },
                rowClassRules: {
                    'ag-changed': (params) => {
                        return (params.data || {}).edited || false
                    },
                },
            },
        }
    },

    computed: {
        resources() {
            return {
                PayrollResource,
                CustomPayrollDetailLineResource,
                AssignmentResource,
                PayCodeResource,
                FeeResource,
                AccountResource,
            }
        },
        payrollAttributes() {
            return this.payrollResource ? this.payrollResource.attributes : {}
        },
        projectId() {
            if (this.projectResource) {
                return this.projectResource.id
            } else {
                if (this.payrollResource) {
                    this.payrollResource.resolveRelated({ managers: ['project'] })
                    return this.payrollResource && this.payrollResource.managers.project.resolved ? this.payrollResource.managers.project.resources[0].id : undefined
                }
            }
        },
        employeeAssignmentNotAdded() {
            return !this.columnDefs.length
        },
        grandTotalAmount() {
            const weeklyAllowancesSum = [this.pc22, this.pc23, this.pc24, this.pc25].reduce((sum, allowance) => {
                const amount = !allowance.amount ? 0 : this.getNumericValueFromFormattedAmount(allowance.amount)
                return isNaN(amount) ? sum : sum + amount
            }, 0)

            return (this.timeEditTotalAmount + weeklyAllowancesSum).toFixed(2)
        },
    },

    watch: {},

    methods: {
        // Handle built-in events
        onGridReady(params: GridReadyEvent): void {
            if (params) {
                this.gridApi = params.api
                this.columnApi = params.columnApi
            }
        },
        onFirstDataRendered(params: FirstDataRenderedEvent): void {
            this.loaded = true
        },
        onCellValueChanged(params: CellValueChangedEvent): void {
            // Check if any change occurred so we can update the corresponding resource
            if (params.value && params.oldValue === params.newValue) return

            const rowNode = this.gridApi.getDisplayedRowAtIndex(params.rowIndex)

            // Mark data as changed for css purposes
            params.data.edited = true
            this.redrawCurrentRow(rowNode)

            this.setCellFocus()
        },

        // Payroll related functions
        async loadAssignment(assignment: AssignmentResource): Promise<void> {
            let assignmentId = parseInt(assignment.id)
            let assignmentUsed = this.isAssignmentUsed(assignmentId)
            if (assignmentUsed) {
                this.columnsSet = true
                this.displayAssignmentUsedAlert = true
                this.potentialAssignment = assignment
            } else {
                this.assignmentResource = assignment
                try {
                    await this.assignmentResource.resolveRelated({ managers: ['employee', 'occ_code'] })
                } catch (error) {
                    this.$emit('message', error)
                }
            }
        },

        cancelAddAssignment() {
            this.$refs.assignmentAutocomplete.clear()
            this.displayAssignmentUsedAlert = false
            this.columnsSet = false
            return
        },

        async confirmAddAssignment() {
            this.assignmentResource = this.potentialAssignment
            try {
                await this.assignmentResource.resolveRelated({ managers: ['employee', 'occ_code'] })
            } catch (error) {
                this.$emit('message', error)
            }
            this.displayAssignmentUsedAlert = false
            this.columnsSet = false
            return
        },

        logger(item) {
            console.log(item)
        },

        updatePayCode(payCodeResource: PayCodeResource, payCode: string) {
            // console.log(payCode, payCodeResource)
            let adding = false
            if (this[`${payCode}`] === undefined || this[`${payCode}`] === null) {
                adding = true
            }
            this[`${payCode}`] = payCodeResource
            if (adding && this.columnDefs.length > 0) {
                const pcComponents = payCode.split('_')
                const pcNum = parseInt(pcComponents[2])

                for (let row of this.rowData) {
                    if (row instanceof TimeEditLine) {
                        row.set(`${payCode}`, this[`${payCode}`])
                    }
                }

                if (pcNum < 22) {
                    let previousIndex = this.columnDefs.findIndex((col) => {
                        return col.headerName === `Hrs${pcNum - 1}` || col.headerName === `Amt${pcNum - 1}`
                    })
                    this.columnDefs.splice(previousIndex + 1, 0, this[`timeEditHoursColumn${pcNum}`])

                    if (this[`${payCode}`].attributes.hnus !== 'HOURLY' && pcNum < 21) {
                        this[`timeEditHoursColumn${pcNum}`].headerName = `Amt${pcNum}`
                    }

                    // this.columnDefs.push(
                    //     this[`timeEditHoursColumn${pcNum}`]
                    // )
                }
            }
            this.agGridKeyVal++
        },

        updateAccount(accountResource: AccountResource, payCode: string) {
            if (this[payCode] === undefined) {
                return
            }

            this[payCode].set('account', accountResource)

            this.agGridKeyVal++
        },

        updateFringe(fringeCode, payCode) {
            if (!this[payCode]) {
                return
            }

            this[payCode].set('customer_fringe_code', fringeCode.target.value)

            this.agGridKeyVal++
        },

        updateHfCode(hfCode, payCode) {
            if (!this[payCode]) {
                return
            }

            this[payCode].set('customer_hf_code', hfCode.target.value)

            this.agGridKeyVal++
        },

        async setMealPenResource() {
            try {
                this.pay_code_21 = await PayCodeResource.detail(112)
            } catch (error) {
                this.$emit('message', error)
            }
        },

        async setKitRentalResource() {
            try {
                this.pay_code_22 = await PayCodeResource.detail(104)
            } catch (error) {
                this.$emit('message', error)
            }
        },

        async setPerDiemResource() {
            try {
                this.pay_code_23 = await PayCodeResource.detail(102)
            } catch (error) {
                this.$emit('message', error)
            }
        },

        async setAdditionalWeeklyResource() {
            this.payCode_23 = new PayCodeResource()
        },

        isAssignmentUsed(assignmentId: number = this.assignmentResource.id): boolean {
            if (!assignmentId) {
                return false
            } else {
                return this.payrollDetailLineResource.find((line) => {
                    if (parseInt(line.attributes.assignment) === assignmentId && !(line.attributes.status === 'DELETED')) {
                        return true
                    }
                })
                    ? true
                    : false
            }
        },

        incrementFees() {
            if (this.numFees < 10) {
                this.numFees++
                let feeObj = {
                    amount: '0.00',
                    feeNum: undefined,
                    feeId: undefined,
                    feeName: undefined,
                    feeType: undefined,
                    feeHours: 0,
                    date: undefined,
                    fringe: undefined,
                    hf: undefined,
                    series: undefined,
                    location: undefined,
                    set: undefined,
                    ff1: undefined,
                    ff2: undefined,
                }
                let lastFee = this.feeData[this.feeData.length - 1]
                if (!lastFee) {
                    feeObj.feeNum = 1
                } else {
                    feeObj.feeNum = lastFee.feeNum + 1
                }

                this.feeData.push(feeObj)
            }
            this.feeKey++
        },

        decrementFees() {
            if (this.numFees > 1) {
                this.numFees--
                this.feeData.pop()
            }
            this.feeKey++
        },

        feeBlur(event, fee) {
            // console.log(event)
            fee.feeId = event.id
            fee.feeName = event.attributes.name
            fee.feeNum = this.feeData.indexOf(fee) + 1
            if (event.attributes.line_type) {
                fee.feeType = event.attributes.line_type
            }
            this.feeKey++
        },

        feeTypeBlur(value, fee) {
            fee.feeType = value
            this.feeKey++
        },

        amountBlur(event, fee) {
            // let feeNum = fee.feeNum
            let amount = event.target.value
            fee.amount = parseFloat(amount).toFixed(2)
            this.feeKey++
        },

        feeHoursBlur(event, fee) {
            let hours = parseFloat(event.target.value).toFixed(2)
            fee.feeHours = hours
        },

        feeAccountBlur(event, fee) {
            let account = event.target.value
            fee.feeAccount = account
        },

        setupNewTimeEditLine() {
            let tel = new TimeEditLine()
            let setupData = undefined
            for (let row of this.rowData) {
                if (row && row.attributes) {
                    setupData = row
                    break
                }
            }
            tel.set('assignment', setupData.attributes.assignment)
            tel.set('employee', setupData.attributes.employee)
            tel.set('transaction_id', setupData.attributes.transaction_id)
            tel.set('transaction', setupData.attributes.transaction_id)
            tel.set('work_date', this.payrollResource.attributes.pay_period_end_date)
            if (!setupData.attributes.work_address) {
                this.getDefaultWorkAddress.then((workAddress) => {
                    tel.set('work_address', workAddress)
                })
            } else {
                tel.set('work_address', setupData.attributes.work_address)
            }
            return tel
        },

        // Create new data entry rows based on attributes of the employee/assignment/project
        async initializeNewRows(options: { numRows?: number; workDays?: number; assignmentResource?: AssignmentResource } = {}): Promise<void> {
            this.columnDefs.push(this.payrollWorkDayOfWeekColumn, this.timeCardWorkDateColumn, this.timeCardRateColumn)
            for (let i = 1; i <= this.numPayCodesDisplaying; i++) {
                if (this[`pay_code_${i}`] !== undefined && this[`pay_code_${i}`].attributes.hnus !== 'HOURLY') {
                    this[`timeEditHoursColumn${i}`].headerName = `Amt${i}`
                }
                this[`timeEditHoursColumn${i}`].headerName = this[`pay_code_${i}`].attributes.code + ' - ' + this[`pay_code_${i}`].attributes.description_check

                this.columnDefs.push(this[`timeEditHoursColumn${i}`])
            }
            this.columnDefs.push(
                this.mealPenaltyColumn,
                this.payrollFeeColumn,
                this.timeCardAmountColumn,
                this.clientAccountColumn,
                this.clientFringeColumn,
                this.clientHandlingFeeColumn,
                this.clientSeriesColumn,
                this.clientLocationColumn,
                this.clientSetColumn,
                this.clientFF1Column,
                this.clientFF2Column,
                this.clientFF3Column,
                this.clientFF4Column,
                this.payrollPensionHoursColumn,
                this.workAddressColumn
            )
            this.columnsSet = true
            let numRows = options.numRows || this.numInitialRows
            let endDate = new Date(this.payrollResource.attributes.pay_period_end_date)
            let startDate = new Date(this.payrollResource.attributes.pay_period_start_date)
            let difference = endDate.getTime() - startDate.getTime()
            let days = difference / (1000 * 3600 * 24)
            if (!numRows || days > numRows) {
                numRows = days
            }

            if (numRows) {
                let workDays: number = days > 7 ? days : 7 // Number of days in timecard

                const dayInMilliseconds: number = 24 * 60 * 60 * 1000
                const tzOffset: number = new Date().getTimezoneOffset()
                const weekEnding: Date = new Date(this.payrollAttributes.pay_period_end_date + ' 12:00:00')
                const firstDate: Date = new Date(weekEnding.getTime() - workDays * dayInMilliseconds) // Set to first day of workweek
                let currentDay = 1
                let weekday = new Array(7)
                weekday[0] = 'Sunday'
                weekday[1] = 'Monday'
                weekday[2] = 'Tuesday'
                weekday[3] = 'Wednesday'
                weekday[4] = 'Thursday'
                weekday[5] = 'Friday'
                weekday[6] = 'Saturday'

                for (let i = 0; i < numRows; i++) {
                    let newRow = new TimeEditLine()

                    if (currentDay <= workDays) {
                        // let curDateObj = new Date(firstDate.getTime() + (currentDay * dayInMilliseconds))
                        const currentDate = this.dateObjectToString(new Date(firstDate.getTime() + currentDay * dayInMilliseconds))
                        newRow.set('ledger_id', this.payrollResource.get('id'))
                        newRow.set('work_date', currentDate)
                        let d = new Date(currentDate)
                        let timezoneOffset = d.getTimezoneOffset()
                        // console.log(timezoneOffset)
                        let hoursOffset = timezoneOffset / 60
                        // console.log(hoursOffset)
                        let timezoneString = `T${String(hoursOffset).padStart(2, '0')}:00:01`

                        let tzAdjustedDateString = `${currentDate}${timezoneString}`
                        let tzAdjusteddateObj = new Date(tzAdjustedDateString)
                        let curWeekday = weekday[tzAdjusteddateObj.getDay()]
                        newRow.set('day_of_week', curWeekday)

                        if (options.assignmentResource || this.assignmentResource) {
                            const currentAssignment = options.assignmentResource || this.assignmentResource
                            // console.log(currentAssignment)
                            if (!currentAssignment || !currentAssignment.id) {
                                this.$emit('message', 'There was an error loading the Assignment.  Please reload and try again.')
                                return
                            }
                            if (!this.payrollResource || !this.payrollResource.id) {
                                this.$emit('message', 'An error has occurred retrieving details about this Payroll.  Please reload and try again.')
                                return
                            }
                            if (!currentAssignment.managers || !currentAssignment.managers.employee.resolved || !currentAssignment.managers.occ_code.resolved) {
                                await currentAssignment.resolveRelated({ managers: ['employee', 'occ_code'] })
                            }
                            newRow.set('transaction_id', this.payrollResource.id)
                            newRow.set('transaction', this.payrollResource.id)
                            newRow.set('assignment', currentAssignment.id)
                            newRow.set('employee', currentAssignment.get('employee.id') || '')
                            newRow.set('hourly_unit_rate', parseFloat(currentAssignment.get('hourly_pay_rate')).toFixed(4) || parseFloat(currentAssignment.get('hourly_pay_rate')).toFixed(4) || '')
                            newRow.set('customer_budget_code', currentAssignment.get('customer_budget_code') || '')
                            newRow.set('customer_fringe_code', currentAssignment.get('customer_fringe_code') || '')
                            newRow.set('customer_hf_code', currentAssignment.get('customer_hf_code') || '')
                            newRow.set('customer_episode', currentAssignment.get('customer_episode') || '')
                            newRow.set('customer_series', currentAssignment.get('customer_series') || '')
                            newRow.set('customer_location', currentAssignment.get('customer_location') || '')
                            newRow.set('customer_set', currentAssignment.get('customer_set') || '')
                            newRow.set('customer_ff1', currentAssignment.get('customer_ff1') || '')
                            newRow.set('customer_ff2', currentAssignment.get('customer_ff2') || '')
                            newRow.set('customer_ff3', currentAssignment.get('customer_ff3') || '')
                            newRow.set('customer_ff4', currentAssignment.get('customer_ff4') || '')
                            newRow.set('occ_code', currentAssignment.get('occ_code.id') || '')
                            for (let i = 1; i <= 25; i++) {
                                if (this[`pay_code_${i}`] !== undefined) {
                                    newRow.set(`pay_code_${i}`, this[`pay_code_${i}`])
                                }
                            }
                            newRow.set('customer_pension_hours', currentAssignment.get('pension_hours_units') || '')
                            try {
                                newRow.set('work_address', await this.getDefaultWorkAddress())
                            } catch (error) {
                                this.$emit('message', error)
                            }
                        }
                        currentDay++
                    }

                    this.newEntryLines.push(newRow)
                }

                this.rowData = this.newEntryLines
            } else {
                this.rowData = []
            }
        },

        async getDefaultWorkAddress() {
            if (this.payrollResource.attributes.default_work_address && this.payrollResource.attributes.default_work_address !== undefined) {
                return this.payrollResource.attributes.default_work_address
            }
            if (!this.projectResource) {
                try {
                    this.projectResource = await ProjectResource.detail(this.payrollResource.attributes.project)
                    await this.projectResource.resolveRelated({ managers: ['work_addresses'] })
                } catch (error) {
                    this.$emit('message', error)
                }
            }
            let projectWorkAddresses = this.projectResource.get('work_addresses')
            let addressId = String(projectWorkAddresses.resources[0].id)

            if (addressId) {
                return addressId
            } else if (this.assignmentResource.attributes.work_address !== undefined) {
                return parseInt(this.assignmentResource.attributes.work_address)
            } else {
                return undefined
            }
        },

        async exportRows() {
            this.exporting = true
            const existingLineCount = this.payrollDetailLineResource.length || 0
            let newDetailLines = []

            this.assignDataForWeeklyAllowancePaycodes()

            for (let fee of this.feeData) {
                let feeLine = this.setupNewTimeEditLine()
                if (fee.date) {
                    feeLine.set('date', fee.date)
                }
                let feeAmount = parseFloat(fee.amount)
                let lineNumber = this.numPayCodes + parseInt(fee.feeNum)
                feeLine.set('transaction_id', this.payrollResource.id)
                feeLine.set('transaction', this.payrollResource.id)
                feeLine.set(`amount_${lineNumber}`, String(feeAmount.toFixed(2)))
                feeLine.set('amount_decimal', String(feeAmount.toFixed(2)))
                feeLine.set('customer_budget_code', fee.feeAccount)
                feeLine.set('assignment', this.assignmentResource.id)
                feeLine.set('occ_code', this.assignmentResource.managers.occ_code.id)
                feeLine.set(`hours_units_${lineNumber}`, '0.00')
                feeLine.set(`pay_code_${lineNumber}`, null)
                feeLine.set('fee', fee.feeId)
                feeLine.set('fee_name', fee.feeName)
                feeLine.set(`fee_${lineNumber}`, fee.feeId)
                feeLine.set('type', fee.feeType)
                feeLine.set(`fee_type_${lineNumber}`, fee.feeType)
                feeLine.set('status', 'EDITED')
                feeLine.set('pension_hours_units', fee.feeHours)
                feeLine.set('customer_fringe_code', fee.fringe)
                feeLine.set('customer_hf_code', fee.hf)
                feeLine.set('customer_series', fee.series)
                feeLine.set('customer_location', fee.location)
                feeLine.set('customer_set', fee.set)
                feeLine.set('customer_ff1', fee.ff1)
                feeLine.set('customer_ff2', fee.ff2)
                feeLine.set('customer_ff3', fee.ff3)
                feeLine.set('customer_ff4', fee.ff4)
                feeLine.set('override', true)
                this.newEntryLines.push(feeLine)
            }

            for (let i = 0; i < this.newEntryLines.length; i++) {
                const line = this.newEntryLines[i]
                // console.log(JSON.stringify(line))
                // if (line.attributes.amount_decimal && parseFloat(line.attributes.amount_decimal) > 0) {
                // console.log(line.toDetailLines())
                try {
                    const lines = await line.toDetailLines()
                    lines.forEach((detail) => {
                        newDetailLines.push(detail)
                    })
                } catch (error) {
                    this.$emit('message', error)
                }
                // }
            }

            this.$emit('save', newDetailLines)
            this.exporting = false
            return newDetailLines
        },

        getNumericValueFromFormattedAmount(formattedAmount: string) {
            const numericString = formattedAmount.replace('$', '').replace(/,/g, '')

            return parseFloat(parseFloat(numericString).toFixed(2))
        },

        assignDataForWeeklyAllowancePaycodes() {
            const payCodeNumbers = ['22', '23', '24', '25']
            payCodeNumbers.forEach((payCodeNumber) => {
                const payCodeProperty = this[`pc${payCodeNumber}`]
                if (payCodeProperty.amount) {
                    let krLine = this.setupNewTimeEditLine()
                    let krAmount = this.getNumericValueFromFormattedAmount(payCodeProperty.amount)
                    let amountDecimal = parseFloat(this.newEntryLines[0].attributes.amount_decimal)
                    amountDecimal += krAmount

                    krLine.set(`amount_${payCodeNumber}`, krAmount)
                    krLine.set('amount_decimal', amountDecimal.toFixed(2))
                    krLine.set(`hours_units_${payCodeNumber}`, '0.00')
                    krLine.set(`pay_code_${payCodeNumber}`, this[`pay_code_${payCodeNumber}`])
                    krLine.set('customer_fringe_code', payCodeProperty.fringe)
                    krLine.set('customer_budget_code', payCodeProperty.account)
                    krLine.set('customer_hf_code', payCodeProperty.hf)
                    krLine.set('customer_series', payCodeProperty.series)
                    krLine.set('customer_location', payCodeProperty.location)
                    krLine.set('customer_set', payCodeProperty.set)
                    krLine.set('customer_ff1', payCodeProperty.ff1)
                    krLine.set('customer_ff2', payCodeProperty.ff2)
                    krLine.set('customer_ff3', payCodeProperty.ff3)
                    krLine.set('customer_ff4', payCodeProperty.ff4)
                    this.newEntryLines.push(krLine)
                }
            })
        },

        close() {
            this.$emit('close', true)
        },

        focusAutocomplete() {},

        setDefaultPayCodes() {
            const uniquePayCodesFromExistingTimeCards = new Set(...[this.defaultPayCodes])

            for (const row of this.payrollDetailLineResource) {
                const payCode = row.attributes.pay_code
                if (payCode) {
                    uniquePayCodesFromExistingTimeCards.add(payCode)
                }
            }

            if (uniquePayCodesFromExistingTimeCards.size) {
                this.defaultPayCodes = Array.from(uniquePayCodesFromExistingTimeCards)
                this.numPayCodesDisplaying = uniquePayCodesFromExistingTimeCards.size
            }
        },

        addPayCode() {
            if (this.numPayCodesDisplaying < this.numPayCodes - 5) {
                this.numPayCodesDisplaying++
                if (this[`pay_code_${this.numPayCodesDisplaying}`] !== undefined) {
                    let colDef = this.columnDefs.find((colDef) => {
                        return colDef.headerName === `Hrs${this.numPayCodesDisplaying}` || colDef.headerName === `Amt${this.numPayCodesDisplaying}`
                    })
                    let colIndex = this.columnDefs.indexOf(colDef)
                    this.columnDefs[colIndex].hide = false
                }
            }
        },

        removePayCode() {
            if (this.numPayCodesDisplaying > 1) {
                const colIndex = this.columnDefs.findIndex((colDef) => {
                    return colDef.headerName === `Hrs${this.numPayCodesDisplaying}` || colDef.headerName === `Amt${this.numPayCodesDisplaying}`
                })
                this.columnDefs[colIndex].hide = true
                this.numPayCodesDisplaying--
            }
        },

        payCodeLabel(pcNum) {
            return `Pay Code ${pcNum}`
        },

        isPcSet(pcNum) {
            return this[`pay_code_${pcNum}`] ? true : false
        },

        formatCurrency(value, event) {
            const valFloat = value ? this.getNumericValueFromFormattedAmount(value) : 0
            const valToFormat = isNaN(valFloat) ? 0 : valFloat

            const formatted = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(valToFormat)

            return formatted.toString()
        },
    },
    beforeMount() {
        // this.navigateToNextCell = this.navigateToNextCell.bind(this);
        if (this.projectResourceAsPassed) {
            this.projectResource = this.projectResourceAsPassed
        }

        this.setDefaultPayCodes()
    },
    async mounted() {
        try {
            await this.setMealPenResource()
            await this.setKitRentalResource()
            await this.setPerDiemResource()
            await this.setAdditionalWeeklyResource()
            if (this.payrollResource && this.payrollResource.attributes) {
                if (!this.projectResource) {
                    let projectRelatedManager = await this.payrollResource.get('project')
                    if (!projectRelatedManager.attributes) {
                        await projectRelatedManager.resolve()
                        this.projectResource = projectRelatedManager.resource
                    } else {
                        this.projectResource = projectRelatedManager
                    }
                } else if (!this.projectResource.attributes && !this.projectResource.resolved) {
                    this.projectResource.resolve()
                }
                if (this.projectResource) {
                    await this.projectResource.resolveRelated({ managers: ['work_addresses'] })
                    let workAddressesMgr = await this.projectResource.get('work_addresses')
                    if (workAddressesMgr.resources.length > 0) {
                        this.projectWorkAddress = workAddressesMgr.resources[0]
                        await this.projectWorkAddress.resolveRelated({ managers: ['city', 'state'] })
                    }
                }
            }
        } catch (error) {
            this.$emit('message', error)
        } finally {
            let feeObj = {
                amount: '0.00',
                date: undefined,
                feeNum: 1,
                feeId: undefined,
                feeType: undefined,
                feeName: undefined,
                feeHours: 0,
                feeAccount: undefined,
            }
            this.feeData.push(feeObj)

            await this.$nextTick()
            if (this.$refs.assignmentAutocomplete) {
                this.$refs.assignmentAutocomplete.$el.querySelector('input').focus()
            }
        }
    },
})
