
import Vue from 'vue'
import { Report, ReportConstructorAttributes } from '../resources/Report'

export default Vue.extend({
    props: {
        payrollId: {
            type: Number,
            required: true,
        },
        initialReports: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            reports: Object.values(this.initialReports).map((reportAttributes: ReportConstructorAttributes) => new Report(reportAttributes)),
            tableFields: [
                {
                    key: 'name',
                    label: 'Name',
                    thStyle: { width: '80%' },
                },
                {
                    key: 'actions',
                    label: 'Actions',
                    class: 'gap-3',
                    thStyle: { width: '20%' },
                },
            ],
        }
    },
    beforeDestroy() {
        this.reports.forEach((r) => r.stopGenerationPolling())
    },
    methods: {
        async regenerateReportUntilDone(report) {
            try {
                await report.regenerateUntilDone()
            } catch (error) {
                this.$emit('message', error)
            }
        },
        async printReport(report) {
            try {
                await report.print(this.payrollId)
            } catch (error) {
                this.$emit('message', error)
            }
        },
    },
})
