
import Vue from 'vue'
import { SigmaAPIClient } from '@sigmacloud/sigma-client/dist/resources/client'
import BaseResource from '@sigmacloud/sigma-client/dist/resources'
import PayrollDetailGrid from '../components/PayrollDetailGrid.vue'
import PayrollReportGenerator from '../components/PayrollReportGenerator.vue'
import Autocomplete from '../components/Autocomplete.vue'
import { AutocompleteResponseAttributes } from '../components/Autocomplete.vue'

import CustomPayrollResource from '../resources/customPayrollResource'
import { PayrollFeeStatusAttributes } from '../resources/customPayrollResource'
import ClientResource from '@sigmacloud/sigma-client/dist/resources/system/client'
import UserResource from '@sigmacloud/sigma-client/dist/resources/user'
import ProjectResource from '@sigmacloud/sigma-client/dist/resources/system/project'
import DepartmentResource from '@sigmacloud/sigma-client/dist/resources/system/department'
import BatchResource from '../../node_modules/@sigmacloud/sigma-client/dist/resources/transactions/batch'
import CompanyResource from '../../node_modules/@sigmacloud/sigma-client/dist/resources/system/company'
import BankResource from '../../node_modules/@sigmacloud/sigma-client/dist/resources/system/bank'
import ProductionResource from '../../node_modules/@sigmacloud/sigma-client/dist/resources/system/production'
import AddressResource from '../../node_modules/@sigmacloud/sigma-client/dist/resources/system/address'
import FeeResource from '../../node_modules/@sigmacloud/sigma-client/dist/resources/system/fee'
import ErrorsMixins from '../mixins/ErrorsMixins'

export default Vue.extend({
    name: 'PayrollEntry',

    data() {
        return {
            id: undefined,
            loading: false,
            payrollResource: undefined,
            latestCalculationId: 0, // Part of the key used to refresh ag-grid
            clientResource: undefined,
            addressResource: undefined,
            projectResource: undefined,
            projectDisplay: undefined,
            projectAddresses: undefined,
            departmentResource: undefined,
            calculating: false,
            hasCalculated: false,
            description: undefined,
            date: undefined,
            weekStarting: undefined,
            weekEnding: undefined,
            checkDate: undefined,
            dueDate: undefined,
            status: undefined,
            statusChoices: ['DRAFT', 'OPEN', 'AUDITED', 'POSTED', 'CLOSED', 'VOIDED', 'DELETED'],
            amount: undefined,
            batch: undefined,
            number: undefined,
            period: undefined,
            commercialJobNum: undefined,
            invoiceInstructions: undefined,
            workCity: undefined,
            companyId: undefined,
            companySelectOptions: [],
            bankId: undefined,
            batchResource: undefined,
            batchDescription: undefined,
            batchName: undefined,
            production: undefined,
            prNumber: undefined,
            workAddresses: undefined,
            workAddObjs: undefined,
            selectedWorkAddress: undefined,
            workAddressChoices: undefined,
            savingPr: false,
            instance: undefined,
            excludedFees: [],
            selectedFee: undefined,
            detailGridKey: 0,
            validationMessage: undefined,
            showValidationAlert: false,
            validating: false,
            editing: false,
            selectedAssignments: [],
            payrollCoOrdinators: [],
            coordinator: undefined,
            companyAddressResources: [],
            companyAddressSelectOptions: [],
            companyAddress: null,
            companyResource: undefined,
            showCalcReminder: false,
            selectedCompanyAddressId: undefined,
        }
    },

    mixins: [ErrorsMixins],

    computed: {
        payrollAttributes() {
            return this.payrollResource ? this.payrollResource.attributes : {}
        },
        clientAttributes() {
            return this.clientResource ? this.clientResource.attributes : {}
        },
        resources() {
            return {
                ClientResource,
                ProjectResource,
                DepartmentResource,
                CustomPayrollResource,
                BatchResource,
                CompanyResource,
                BankResource,
                ProductionResource,
                AddressResource,
                FeeResource,
            }
        },
        calcSpinner() {
            return this.calculating || this.validating
        },
        saveSpinner() {
            if (this.savingPr || this.calculating) {
                return true
            } else {
                return false
            }
        },
        editable() {
            let status = undefined
            if (this.payrollResource && this.payrollResource.attributes) {
                status = this.payrollResource.attributes.status
            }

            if (status === 'POSTED' || status === 'CLOSED' || status === 'VOIDED') {
                return false
            } else {
                return true
            }
        },
    },

    props: {
        instanceGetter: Function,
        tokenGetter: Function,
    },

    components: {
        PayrollDetailGrid,
        Autocomplete,
        PayrollReportGenerator,
    },

    methods: {
        logger(item) {
            console.log(item)
        },
        async getPayroll(): Promise<boolean> {
            try {
                this.payrollResource = await CustomPayrollResource.detail(this.id)
                this.status = this.payrollResource.attributes.status
                await this.payrollResource.resolveRelated()
                await this.payrollResource.resolveAttribute('project.client')
                this.projectResource = await this.payrollResource.get('project')
                await this.projectResource.resolveRelated()
                this.clientResource = await this.projectResource.get('client')
                this.workAddresses = await this.payrollResource.get('project.work_addresses').value
                let batchAddress = await this.payrollResource.get('batch.company_address')
                let companyAddress
                batchAddress ? (companyAddress = batchAddress) : (companyAddress = await this.payrollResource.get('project.company_address'))
                this.batchResource = await this.payrollResource.get('batch')
                await this.getWorkAddresses()
                if (companyAddress) await this.getCompanyAddress(companyAddress)
            } catch (apiError) {
                this.$emit('message', apiError)
                return false
            }
            return true
        },
        async getPayrollCoOrdinators() {
            let users: any = await UserResource.filterAll({ is_staff: true, ordering: 'last_name' })
            users = users
                .filter((e: { attributes: { first_name: any; last_name: any } }) => e.attributes.first_name || e.attributes.last_name)
                .map((e: { attributes: { id: any; last_name: any; first_name: any } }) => {
                    return {
                        value: e.attributes.id,
                        text: `${e.attributes.last_name} ${e.attributes.first_name}`,
                    }
                })
            this.payrollCoOrdinators = users
        },

        async getCompanyAddress(companyAddress) {
            await this.clientResource.resolveRelated()
            let clientCompanyAddress = await this.clientResource.get('company_address')
            let companyAddressIds = await this.clientResource.get('company.company_addresses')

            if (!companyAddressIds) {
                return
            }
            if (companyAddressIds.length) {
                let addresses = new Array()
                for (let addressId of companyAddressIds) {
                    let addressResource = await AddressResource.detail(addressId)
                    await addressResource.resolveRelated({ managers: ['city', 'state'] })
                    addresses.push(addressResource)
                }
                this.companyAddressResources = addresses
                this.companyAddressSelectOptions = addresses.map((address) => {
                    return {
                        value: address.id,
                        text: `${address.get('line1')}, ${address.get('city.name')}, ${address.get('state.code')}, ${address.get('postal')}`,
                    }
                })
                if (!addresses.length) this.companyAddressSelectOptions = { value: null, text: '------------' }
            } else {
                this.companyAddressResources = []
            }

            // if it is null the default company address will be populated in the front end.
            this.companyAddress = companyAddress || clientCompanyAddress
        },

        async getWorkAddresses() {
            let addressResources = new Array()
            for (let workAddress of this.workAddresses) {
                try {
                    let waResource = await AddressResource.detail(workAddress)
                    await waResource.resolveRelated()
                    addressResources.push(waResource)
                } catch (error) {
                    this.$emit('message', error)
                }
            }
            this.workAddObjs = addressResources
            let choices = new Array()
            for (let address of addressResources) {
                let choice = {
                    value: address.id,
                    text: `${address.attributes.line1}, ${address.get('city.name')}, ${address.get('state.code')} ${address.attributes.postal} `,
                }
                choices.push(choice)
            }
            this.workAddressChoices = choices
            this.selectedWorkAddress = this.workAddressChoices[0].value
        },

        async getCompanyOptions() {
            const response = await CompanyResource.filterAll({})

            this.companySelectOptions = response.map((companyResource) => ({
                id: companyResource.attributes.id,
                text: companyResource.attributes.name,
                bankId: companyResource.attributes.bank,
            }))

            this.companySelectOptions = [{ text: 'Select company', disabled: true }, ...this.companySelectOptions]
        },

        // Save the data into the payroll attribute
        async updatePayrollResourceAttribute(attribute: string, resource: AutocompleteResponseAttributes): Promise<void> {
            if (resource && resource.id) {
                try {
                    await resource.resolveRelated()
                    this.payrollResource.set(attribute, resource.id)
                } catch (error) {
                    this.$emit('message', error)
                }
            }
        },

        // Calls built-in Resource save method
        async savePayrollResource(): Promise<void> {
            let payrollStatusBeforeSave = this.payrollResource.attributes.status
            this.$emit('hide-all-errors')

            try {
                this.savingPr = true
                this.payrollResource.attributes.status = this.status

                await this.batchResource.validate()
                if (this.batchResource.changes && Object.keys(this.batchResource.changes).length > 0) {
                    await this.batchResource.save()
                }
                await this.payrollResource.validate()
                if (this.payrollResource.changes && Object.keys(this.payrollResource.changes).length > 0) {
                    await this.payrollResource.saveWithPostedStatusHandling()
                    this.savingPr = false
                } else {
                    this.savingPr = false
                }

                this.status = this.payrollResource.attributes.status
            } catch (error) {
                this.payrollResource.attributes.status = payrollStatusBeforeSave

                if (error.response) {
                    this.$emit('message', error.response)
                }

                if (error.messages) {
                    error.messages.forEach((message) => {
                        this.$emit('message', message)
                    })
                } else {
                    this.$emit('message', error)
                }
            } finally {
                this.showCalcReminder = this.payrollResource.attributes.status !== 'POSTED'
                this.savingPr = false
                this.detailGridKey++
            }
        },

        // The client is not part of the model, but we can use it to restrict choices down the road.
        async calcPayrollResource(): Promise<void> {
            this.validationMessage = undefined
            this.validating = false
            this.showValidationAlert = false
            this.showCalcReminder = false
            if (this.calculating) {
                return
            }

            let result: PayrollFeeStatusAttributes
            let calculated: CustomPayrollResource

            try {
                this.loading = true
                this.calculating = true
                result = await this.payrollResource.calculate({ background: true }, this.selectedAssignments)
                calculated = await this.payrollResource.wait(result.fee_calc_status_id).catch((error) => {
                    this.$emit('message', error)
                })
                if (calculated) {
                    this.calculating = false
                }
            } catch (apiError) {
                this.$emit('message', apiError)
                this.calculating = false
                calculated = await CustomPayrollResource.detail(this.payrollResource.id)
            } finally {
                this.calculating = false
            }

            if (calculated) {
                this.payrollResource = calculated
                try {
                    await this.payrollResource.resolveRelated({ managers: ['project', 'department'] })
                    this.latestCalculationId = result.fee_calc_status_id
                } catch (error) {
                    this.$emit('message', error)
                }
            }

            this.hasCalculated = true
            this.calculating = false
            this.loading = false
        },

        // The client is not part of the model, but we can use it to restrict choices down the road.
        async updateClient(event: AutocompleteResponseAttributes): Promise<void> {
            const clientId = event.id || undefined
            try {
                this.clientResource = await ClientResource.detail(clientId)
            } catch (error) {
                this.$emit('message', error)
            }
        },

        async updateProduction(event: AutocompleteResponseAttributes): Promise<void> {
            const productionId = event.id || undefined
            try {
                this.production = await ProductionResource.detail(productionId)
            } catch (error) {
                this.$emit('message', error)
            }
        },

        updateAddress(event: AutocompleteResponseAttributes): void {
            this.addressResource = event
        },

        updateCompanyAddress(): void {
            this.batchResource.set('company_address', this.companyAddress)
        },

        async updateExistingPrAddress(event) {
            try {
                let addressResource = await AddressResource.detail(event)
                this.payrollResource.set('work_city', addressResource.attributes.city)
                this.payrollResource.set('state', addressResource.attributes.state)
                this.payrollResource.set('work_address', addressResource.attributes.id)
            } catch (error) {
                this.$emit('message', error)
            }
        },

        updateDescription(event) {
            if (this.payrollResource) {
                this.payrollResource.set('description', event)
            }
        },

        updateCompanyAndRelatedBank(companyId) {
            const selectedCompanyOption = this.companySelectOptions.find((option) => option.id === companyId)

            if (!selectedCompanyOption || !selectedCompanyOption.bankId) {
                return
            }

            this.companyId = companyId
            this.bankId = selectedCompanyOption.bankId
            this.resetProject()
            this.$refs['project-autocomplete'].clear()
        },

        toggleEditing() {
            this.editing = !this.editing
        },

        async updateProject(event: AutocompleteResponseAttributes): Promise<void> {
            try {
                let projectResource = await ProjectResource.detail(event.id)
                await projectResource.resolveRelated()
                this.projectResource = projectResource
                this.projectDisplay = `${event.attributes.number} - ${event.attributes.name}`
                this.commercial_job_number = event.attributes.commercial_job_number
                this.clientResource = await projectResource.get('client')
                this.coordinator = this.projectResource.attributes.payroll_coordinator
                await this.getCompanyAddress(projectResource.get('company_address'))
                let wam = projectResource.managers.work_addresses
                await wam.resolve()
                this.addressResource = wam.resources[0]
            } catch (error) {
                this.$emit('message', error)
            }
        },

        resetProject() {
            this.projectResource = undefined
            this.projectDisplay = undefined
            this.commercial_job_number = undefined
            this.clientResource = undefined
            this.coordinator = undefined
            this.addressResource = undefined
        },

        updateDepartment(event: AutocompleteResponseAttributes): void {
            this.deapartmentResource = event
        },

        addExcludedFee(event) {
            let feeSet = new Set(this.excludedFees)
            feeSet.add(event.id)
            this.excludedFees = Array.from(feeSet)
        },

        removeFee() {
            let filteredFees = this.excludedFees.filter((value, index, arr) => {
                return value !== this.selectedFee
            })
            this.excludedFees = filteredFees
        },

        async saveNewPr() {
            if (!this.companyId) {
                this.$emit('message', 'Could not find Company.  Please select a Company and try again.')
                return
            }
            if (!this.bankId) {
                this.$emit('message', 'Could not find Bank.  Please correct in Company setup and try again.')
                return
            }
            if (!this.production || !this.production.id) {
                this.$emit('message', 'Could not find Production details.  Please add a Production and try again.')
                return
            }
            if (!this.departmentResource || !this.departmentResource.id) {
                this.$emit('message', 'Could not find Department.  Please add a Department and try again.')
                return
            }
            if (!this.addressResource || !this.addressResource.attributes) {
                this.$emit('message', 'Could not find a default address for the Project.  Please add one and try again.')
                return
            }
            this.savingPr = true
            let ableToSave = true
            let newBatch = new BatchResource()
            let newPr = new CustomPayrollResource()

            //Save Batch
            newBatch.set('name', this.batchName)
            newBatch.set('description', this.batchDescription)
            newBatch.set('active', true)
            newBatch.set('bank', this.bankId)
            newBatch.set('production', this.production.id)
            newBatch.set('company', this.companyId)
            newBatch.set('company_address', this.companyAddress)
            let batchResponse
            try {
                batchResponse = await newBatch.save()
            } catch (error) {
                ableToSave = false
                this.$emit('message', error)
                this.savingPr = false
                return
            }
            let savedBatch = batchResponse.resources[0]
            let batchId = savedBatch.id

            // Setup new Payroll
            newPr.set('batch', batchId)
            newPr.set('status', this.status)
            newPr.set('number', this.prNumber)
            newPr.set('description', this.description)
            newPr.set('date', this.date)
            newPr.set('amount_decimal', this.amount)
            newPr.set('date_due', this.dueDate)
            newPr.set('check_date', this.checkDate)
            newPr.set('commercial_job_number', this.commercialJobNum)
            newPr.set('invoice_instructions', this.invoiceInstructions)
            newPr.set('pay_period_start_date', this.weekStarting)
            newPr.set('pay_period_end_date', this.weekEnding)
            newPr.set('project', this.projectResource.id)
            newPr.set('state', this.addressResource.attributes.state)
            newPr.set('work_city', this.addressResource.attributes.city)
            newPr.set('work_address', this.addressResource.attributes.id)
            newPr.set('department', this.departmentResource.id)
            newPr.set('excluded_fees', this.excludedFees)
            newPr.set('payroll_coordinator', this.coordinator)

            let requiredFields = ['number', 'description', 'date', 'amount_decimal', 'date_due', 'check_date', 'pay_period_start_date', 'pay_period_end_date', 'batch', 'project', 'state', 'work_city', 'payroll_coordinator']

            // Check required fields
            for (let field of requiredFields) {
                let fieldVal = newPr.attributes[field]
                if (fieldVal === undefined || fieldVal === null || fieldVal.length === 0) {
                    let fieldNameComponents = field.split('_')
                    for (let index in fieldNameComponents) {
                        let word = fieldNameComponents[index]
                        fieldNameComponents[index] = word.charAt(0).toUpperCase() + word.slice(1)
                    }
                    let formattedFieldName = fieldNameComponents.join(' ')
                    this.$emit('message', `${formattedFieldName} is a required field`)
                    ableToSave = false
                }
            }

            // Save Payroll
            if (ableToSave) {
                try {
                    let prSaveResponse = await newPr.save()
                    this.savingPr = false
                    let savedPrResource = prSaveResponse.resources[0]
                    let newPrId = savedPrResource.id
                    this.id = newPrId
                    this.getPayroll()
                    this.$router.push({ path: `/payroll/${newPrId}` })
                } catch (error) {
                    this.$emit('message', error)
                    this.savingPr = false
                }
            } else {
                this.savingPr = false
            }
        },

        emitError(error, options) {
            this.$emit('message', error)

            if (!options.preventGridRerender) {
                this.detailGridKey++
            }
        },

        cancelCalc() {
            this.validationMessage = undefined
            this.showValidationAlert = false
            this.validating = false
            this.calculating = false
            this.savingPr = false
            this.detailGridKey++
        },

        selectAssignmentsForCalc(selectedAssignments) {
            this.selectedAssignments = selectedAssignments
        },

        async validatePayroll() {
            if (this.validating) {
                return
            }
            this.$emit('hide-all-errors')

            this.validating = true
            let payrollId = this.payrollResource.id
            let queryString = '/validate?background=true'
            if (this.selectedAssignments && this.selectedAssignments.length) {
                queryString += `&limit_to_assignments__in=`
                for (let assignment of this.selectedAssignments) {
                    queryString += `${assignment},`
                }
                if (queryString.charAt(queryString.length - 1) === ',') {
                    queryString = queryString.substr(0, queryString.length - 1)
                }
            }
            let validateResource = CustomPayrollResource.wrap(`/${payrollId}${queryString}`)
            try {
                let response
                let validateStatusId
                this.validating = true
                let validationStatusCheckResponse = (await validateResource.get()) as any
                if (validationStatusCheckResponse.data.validate_status_id) {
                    // wait
                    validateStatusId = validationStatusCheckResponse.data.validate_status_id
                    response = await this.payrollResource.validateWait(validateStatusId)
                    this.validating = false
                }
                let completedValidationRequest = CustomPayrollResource.wrap(`/${payrollId}/validate_status?id=${validateStatusId}`)
                let completedValidatonResponse = (await completedValidationRequest.get()) as any

                if (completedValidatonResponse.data && completedValidatonResponse.data.status === 'DONE') {
                    this.validationMessage = completedValidatonResponse.data.output_messages
                }
                if (this.validationMessage.length === 0) {
                    this.showValidationAlert = false
                    await this.calcPayrollResource()
                    return
                } else {
                    this.validationMessage.forEach((message, index) => {
                        this.validationMessage[index] = `${index + 1}: ${message}`
                    })
                    this.showValidationAlert = true
                }
            } catch (error) {
                this.$emit('message', error)
                this.validating = false
            }
        },
        async setDefaultPayroll() {
            let productions = await ProductionResource.list()
            this.production = productions.resources[0]

            let curDate = new Date()
            let batchNameString = `Payroll Batch ${curDate.getFullYear()}-${curDate.getMonth()}-${curDate.getDate()} ${curDate.getHours()}:${curDate.getMinutes()}`
            this.batchName = batchNameString

            let prNumString = `A${curDate.getFullYear()}${String(curDate.getMonth()).padStart(2, '0')}${String(curDate.getDate()).padStart(2, '0')}${String(curDate.getHours()).padStart(2, '0')}${String(curDate.getMinutes()).padStart(2, '0')}${String(curDate.getSeconds()).padStart(2, '0')}`
            this.prNumber = prNumString

            let departments = await DepartmentResource.list()
            this.departmentResource = departments.resources[0]

            this.status = this.statusChoices[0]

            let month = '' + (curDate.getMonth() + 1)
            let day = '' + curDate.getDate()
            let year = curDate.getFullYear()
            if (month.length < 2) {
                month = '0' + month
            }
            if (day.length < 2) {
                day = '0' + day
            }
            this.date = [year, month, day].join('-')

            this.amount = '1.00'
        },
    },

    created() {
        const instance = this.$props.instanceGetter()
        this.instance = instance

        const token = this.$props.tokenGetter()
        BaseResource.client = new SigmaAPIClient(instance, token)

        this.id = this.$route.params.id
        if (!this.id) {
            this.payrollResource = new CustomPayrollResource()
        }
    },

    async mounted() {
        try {
            await Promise.all([this.getPayrollCoOrdinators(), this.getCompanyOptions()])

            if (this.id) {
                this.getPayroll()
            } else {
                this.setDefaultPayroll()
            }
        } catch (error) {
            this.$emit('message', error)
        }
    },
})
