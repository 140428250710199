<script>
import Vue from 'vue'

export default Vue.extend({
    name: 'AssignmentSelect',
    props: {
        assignmentResources: {
            type: Array,
            required: false,
        },
    },
    data() {
        return {
            availableAssignments: [],
            selectedAssignments: [],
        }
    },
    async mounted() {
        for (let assignmentResource of this.assignmentResources) {
            let assignmentSelectionString = ''
            let employeeFirstName = await assignmentResource.get('employee.first_name')
            let employeeLastName = await assignmentResource.get('employee.last_name')
            let tinLastFour = await assignmentResource.get('employee.tin_last_4')
            let isLoanout = await assignmentResource.get('employee.is_loanout')
            if (isLoanout) {
                assignmentSelectionString = await assignmentResource.get('employee.loanout_name')
            } else {
                assignmentSelectionString = `${employeeLastName}, ${employeeFirstName} - ${tinLastFour}`
            }
            this.availableAssignments.push({
                value: assignmentResource.id,
                text: assignmentSelectionString,
            })
        }
    },
})
</script>
<template>
    <div v-if="assignmentResources">
        <h5>Select assignments to calculate:</h5>
        <b-form-select multiple :select-size="25" :options="availableAssignments" v-model="selectedAssignments"></b-form-select>
        <div>Shift + click to select multiple adjacent assignments. Ctrl + click (Windows) or Cmd + click (Mac) to select non-adjacent assignments.</div>
        <div class="d-flex justify-content-end"><b-button class="ml-2 mr-2 mt-2 mb-2" @click="$emit('cancel')">Cancel</b-button><b-button class="ml-2 mr-2  mt-2 mb-2" variant="success" @click="$emit('selectAssignments', selectedAssignments)">Select</b-button></div>
    </div>
</template>
