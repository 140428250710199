import Vue from 'vue'
import VueRouter from 'vue-router'
import Adjustments from '../components/Adjustments.vue'
import PayrollEntry from '../components/PayrollEntry.vue'
import PayrollList from '../components/PayrollList.vue'
import PayrollValidationGrid from '../components/PayrollValidationGrid.vue'
import PayrollStatusTracker from '../components/PayrollStatusTracker.vue'
import PrintPaychecks from '../components/PrintPaycheks.vue'
import System from '../components/System.vue'

Vue.use(VueRouter)

const routes: any[] = [
    // Need this route to get the ID of the payroll
    {
        path: '/',
        name: 'Home',
        component: PayrollEntry,
        props: true,
    },
    {
        path: '/payroll/:id?',
        name: 'PayrollEntry',
        component: PayrollEntry,
    },
    {
        path: '/payroll/:id?/validation',
        name: 'PayrollValidation',
        component: PayrollEntry,
        props: true,
    },
    {
        path: '/post',
        name: 'PostPayroll',
        component: PayrollList,
        props: false,
    },
    {
        path: '/tracker',
        name: 'Tracker',
        component: PayrollStatusTracker,
        props: false,
    },
    {
        path: '/print',
        name: 'PrintPaychecks',
        component: PrintPaychecks,
        props: false,
    },
    {
        path: '/adjustments',
        name: 'Adjustments',
        component: Adjustments,
        props: false,
    },
    {
        path: '/system',
        name: 'System',
        component: System,
        props: false,
    },
]

const router = new VueRouter({
    base: process.env.BASE_URL,
    mode: 'history',
    routes,
})

export default router
