import Vue from 'vue'

export default Vue.extend({
    methods: {
        dateObjectToString(date: Date): string {
            let year = date.getFullYear()
            let month = this.pad(date.getMonth() + 1)
            let day = this.pad(date.getDate())

            return year + '-' + month + '-' + day
        },

        pad(number: number): string {
            return number < 10 ? '0' + number : number.toString()
        },
    },
})
