
import Vue from 'vue'
import Adjustments from './components/Adjustments.vue'
import PayrollEntry from './components/PayrollEntry.vue'
import PayrollList from './components/PayrollList.vue'
import PayrollValidationGrid from './components/PayrollValidationGrid.vue'
import PrintPaychecks from './components/PrintPaycheks.vue'
import PayrollStatusTracker from './components/PayrollStatusTracker.vue'
import System from './components/System.vue'
import Sigma from '@sigmacloud/sigma-client'
import { createTokenPrefetchInterceptor } from '@sigmacloud/sigma-client/dist/resources/client'
import { badRequest } from '@sigmacloud/sigma-client/dist/util/exceptions'
import ErrorsMixin from './mixins/ErrorsMixins'
import UserMixin from './mixins/UserMixin'

export default Vue.extend({
    components: {
        PayrollEntry,
        PayrollList,
        PayrollValidationGrid,
        PrintPaychecks,
        Adjustments,
        System,
        PayrollStatusTracker,
    },
    mixins: [ErrorsMixin, UserMixin],
    data() {
        return {
            token: '',
            instance: 'https://',
            username: '',
            password: '',
            payrollId: undefined,
            logoutAlertVisible: false,
            peKey: 0,
            user: undefined,
            instanceKey: 0,
        }
    },
    watch: {
        instance(newValue, oldValue) {
            let pathComponents = this.instance.split('/')
            if (!pathComponents[0].includes('http')) {
                if (pathComponents[0].includes(':') || pathComponents[0] === 'htt' || pathComponents[0] === 'ht' || pathComponents[0] === 'h') {
                    pathComponents.splice(0, 1)
                }
                let correctedUrl = 'https://' + pathComponents.join('/')
                this.instance = correctedUrl
            }
            if (newValue !== oldValue) {
                this.instanceKey++
            }
        },
    },
    methods: {
        async login() {
            if (!this.token) {
                //normalize instanceUrl
                let instanceUrl = this.instance.toLowerCase().trim()
                if (instanceUrl.charAt(instanceUrl.length - 1) === '/') {
                    instanceUrl = instanceUrl.slice(0, -1)
                }
                let token = this.getTokenForInstance(instanceUrl)
                if (token !== undefined && token.length) {
                    this.token = token
                    this.$data.token = token
                    Sigma.setInstance(instanceUrl, token)
                    this.checkForExpiredToken()
                    return
                }
                try {
                    Sigma.setInstance(this.$data.instance)
                    let result = await Sigma.login(this.$data.username, this.$data.password)
                    if (result && result.token) {
                        this.$data.token = result.token
                        this.instance = this.$data.instance
                        let instanceObj = {
                            instanceUrl: instanceUrl,
                            token: result.token,
                        }
                        this.updateInstances(instanceObj)
                        Sigma.client.axios.interceptors.request.use(createTokenPrefetchInterceptor(Sigma.client))
                        this.user = await this.getUser()
                    } else {
                        throw new Error(JSON.stringify(result))
                    }
                } catch (apiErr) {
                    this.$emit('message', apiErr)
                    this.errorMessage(`Authentication Failed - ${apiErr.message}`)
                    this.logout()
                    if (badRequest(apiErr)) {
                        // Definitely is AxiosError
                        this.errorMessage('Authentication Failed')
                    } else {
                        this.errorMessage(apiErr.name)
                        throw apiErr
                    }
                }
            }
        },

        updateInstances(instance) {
            let instances = JSON.parse(localStorage.getItem('sigmacloud.approvals.instances')) || []
            //find existing to update
            if (instances.length) {
                let existingInstance = instances.find((inObj) => {
                    return inObj.instanceUrl === instance.instanceUrl
                })
                if (existingInstance && typeof existingInstance === 'object') {
                    let index = instances.indexOf(existingInstance)
                    instances[index].token = instance.token
                } else {
                    instances.push(instance)
                }
            } else {
                instances.push(instance)
            }
            let stringifiedInstances = JSON.stringify(instances)
            localStorage.setItem('sigmacloud.approvals.instances', stringifiedInstances)
            localStorage.setItem('sigmacloud.approvals.recentInstance', instance.instanceUrl)
        },

        getTokenForInstance(instanceUrl) {
            let instances = JSON.parse(localStorage.getItem('sigmacloud.approvals.instances')) || []
            if (instances.length) {
                let instance = instances.find((inObj) => {
                    return inObj.instanceUrl === instanceUrl
                })
                if (instance && typeof instance === 'object') {
                    return instance.token
                }
            }
            return undefined
        },

        deleteTokenForInstance(instanceUrl) {
            let instances = JSON.parse(localStorage.getItem('sigmacloud.approvals.instances')) || []
            if (instances.length) {
                let instanceObj = instances.find((inObj) => {
                    return inObj.instanceUrl === instanceUrl
                })
                let index = instances.indexOf(instanceObj)
                instances[index].token = ''
                let stringifiedInstances = JSON.stringify(instances)
                localStorage.setItem('sigmacloud.approvals.instances', stringifiedInstances)
            }
        },

        checkForExpiredToken() {
            let payload = Sigma.client.getTokenPayload()
            if (!payload || !payload.exp) {
                this.logout()
                return
            }
            let expDate = new Date(payload.exp * 1000)
            let curDate = Date.now()
            if (expDate.getTime() <= curDate) {
                this.logout()
            }
        },

        showLogoutAlert() {
            this.logoutAlertVisible = true
        },

        hideLogoutAlert() {
            this.logoutAlertVisible = false
        },

        logout() {
            let normalizedInstanceUrl = this.instance.toLowerCase().trim()
            if (normalizedInstanceUrl.charAt(normalizedInstanceUrl.length - 1) === '/') {
                normalizedInstanceUrl = normalizedInstanceUrl.slice(0, -1)
            }
            this.deleteTokenForInstance(normalizedInstanceUrl)
            this.token = undefined
            this.logoutAlertVisible = false
        },

        navigateToNewPayroll() {
            this.peKey++
            this.$router.push({ name: 'PayrollEntry', params: { id: undefined } })
            this.peKey++
        },

        errorMessage(error) {
            this.updateErrors(error)
        },
    },
    async mounted() {
        if (window.location.href.substring(0, 5) !== 'https' && window.location.href.includes('sigmacloud.io')) {
            window.location.href = window.location.href.replace('http', 'https')
        }
        let recentInstance = localStorage.getItem('sigmacloud.approvals.recentInstance')
        if (recentInstance && recentInstance.length) {
            this.instance = recentInstance
            this.$data.instance = recentInstance
            let token = this.getTokenForInstance(recentInstance)
            if (token && token.length) {
                this.token = token
                this.$data.token = token
                Sigma.setInstance(recentInstance, token)
                this.checkForExpiredToken()
                try {
                    this.user = await this.getUser()
                } catch (error) {
                    this.$emit('message', error)
                }
            }
        }
    },
})
