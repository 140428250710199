<script>
import Vue from 'vue'
import BatchResource from '@sigmacloud/sigma-client/dist/resources/transactions/batch'
import ErrorsMixins from '../mixins/ErrorsMixins'

export default Vue.extend({
    data() {
        return {
            bankString: undefined,
        }
    },
    mixins: [ErrorsMixins],
    async mounted() {
        try {
            let batchResource = await BatchResource.detail(this.params.data.batch)
            let bankName = await batchResource.resolveAttribute('bank.name')
            let bankCode = await batchResource.resolveAttribute('bank.code')
            this.bankString = `${bankCode} - ${bankName}`
        } catch (error) {
            this.$emit('message', error)
        }
    },
})
</script>

<template>
    <div>
        <span v-if="bankString">{{ bankString }}</span>
    </div>
</template>
