<script>
import Vue from 'vue'
import ProjectResource from '@sigmacloud/sigma-client/dist/resources/system/project'
import ErrorsMixins from '../mixins/ErrorsMixins'

export default Vue.extend({
    data() {
        return {
            projectName: undefined,
        }
    },
    mixins: [ErrorsMixins],
    async mounted() {
        try {
            let projectResource = await ProjectResource.detail(this.params.data.project)
            if (projectResource) {
                this.projectName = projectResource.attributes.name
            }
        } catch (error) {
            this.$emit('message', error)
        }
    },
})
</script>

<template>
    <div>
        <span v-if="projectName">{{ projectName }}</span>
    </div>
</template>
