import Vue from 'vue'
import crypto from 'crypto'

export default Vue.extend({
    data() {
        return {
            msg: [],
        }
    },
    methods: {
        updateErrors(error) {
            let errorObj = {}
            // console.log('updating: ', error, typeof error)
            if (error instanceof Error || typeof error === 'object') {
                try {
                    errorObj = error.toJSON()
                } catch (err) {
                    errorObj.message = error.toString()
                }
            } else if (typeof error === 'string') {
                errorObj.message = error
            }
            if (!errorObj.message) {
                errorObj.message = JSON.stringify(error)
            }

            this.msg.push(errorObj.message)
            let idString = crypto.randomBytes(20).toString('hex')
            errorObj.id = idString
            let curDateTime = Date.now()
            errorObj.timestampRawValue = curDateTime
            errorObj.timestampStringVal = ''
            errorObj.timestampStringVal = Date(curDateTime)

            //set errorObj.baseURL to instance if not existing.  Try to get endpoint as well

            let errors = JSON.parse(localStorage.getItem('sigmacloud.approvals.errors')) || []
            errors.push(errorObj)
            let stringifiedErrors = JSON.stringify(errors)
            localStorage.setItem('sigmacloud.approvals.errors', stringifiedErrors)
            if (errorObj.message.includes('401')) {
                this.$router.push('/')
            }
        },
        getErrors() {
            let errors = JSON.parse(localStorage.getItem('sigmacloud.approvals.errors'))
            if (!errors || !errors.length) {
                return []
            } else {
                return errors
            }
        },
        deleteError(errorId) {
            let errors = this.getErrors()
            let indexToDelete = errors.findIndex((error) => {
                if (error.id === errorId) {
                    console.log('Delete match found, id', error.id)
                }
                return error.id === errorId
            })
            // console.log(indexToDelete)
            errors.splice(indexToDelete, 1)
            let stringifiedErrors = JSON.stringify(errors)
            // console.log(errors, stringifiedErrors)
            localStorage.setItem('sigmacloud.approvals.errors', stringifiedErrors)
        },
        hideAllErrors() {
            this.msg = []
        },
        clearAllErrors() {
            localStorage.setItem('sigmacloud.approvals.errors', JSON.stringify([]))
        },
    },
})
