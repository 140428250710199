<script>
import Vue from 'vue'

/*
 * Import Ag-Grid files and Enterprise License
 */
import { AgGridVue } from 'ag-grid-vue'
import 'ag-grid-enterprise'
import { LicenseManager } from 'ag-grid-enterprise'
LicenseManager.setLicenseKey('CompanyName=Revolution Payroll,LicensedGroup=ProBooks,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-021824,ExpiryDate=4_December_2022_[v2]_MTY3MDExMjAwMDAwMA==0723da76405e8e4969df68bfe317209f')

import { SigmaAPIClient } from '@sigmacloud/sigma-client/dist/resources/client'
import BaseResource from '@sigmacloud/sigma-client/dist/resources'
import PayrollResource from '../resources/customPayrollResource'

var errorLevels = {
    DEBUG: 'Debug',
    INFO: 'Informational',
    WARN: 'Warning',
    CRITICAL: 'Critical',
}

export default Vue.extend({
    name: 'PayrollValidationGrid',

    components: {
        AgGridVue,
    },

    props: {
        payrollResource: {
            type: PayrollResource,
            required: false,
        },
        instanceGetter: Function,
        tokenGetter: Function,
        pathId: String,
    },

    data() {
        return {
            payrollInstance: undefined,
            id: undefined,
            validationGridOptions: {
                rowSelection: 'multiple',
                suppressRowClickSelection: true,
                rowClassRules: {
                    AGRowWarning: 'data.error_level == "WARN"',
                    AGRowCritical: 'data.error_level == "CRITICAL"',
                },
                defaultColDef: {
                    sortable: true,
                    filter: true,
                },
            },
            columnDefs: [
                {
                    headerName: 'Error level',
                    field: 'error_level',
                    checkboxSelection: true,
                    valueGetter: (params) => {
                        return errorLevels[params.data.error_level]
                    },
                    comparator: (valueA, valueB, nodeA, nodeB) => {
                        function get_error_index(node) {
                            return Object.keys(errorLevels).indexOf(node.data.error_level)
                        }
                        return get_error_index(nodeA) - get_error_index(nodeB)
                    },
                },
                {
                    headerName: 'Message',
                    field: 'message',
                    filter: 'agTextColumnFilter',
                    width: 800,
                    resizable: true,
                },
            ],
            rowData: [],
            isAuditing: false,
        }
    },
    methods: {
        async getPayroll(id) {
            try {
                this.payrollInstance = await PayrollResource.detail(id)
            } catch (apiError) {
                this.$emit('message', apiError)
            }
        },
        async auditPayroll() {
            this.isAuditing = true
            try {
                let audit_data = await this.payrollInstance.wrap('/audit_report').get()
                this.rowData = audit_data.data
                for (const row of this.rowData) {
                    for (const column of Object.keys(row)) {
                        let columnDef = {
                            headerName: column.replace('_', ' ').replace(/^./, function(match) {
                                return match.toUpperCase()
                            }),
                            field: column,
                        }
                        if (!this.columnDefs.map((col) => col.field).includes(columnDef.field)) {
                            this.columnDefs.push(columnDef)
                        }
                    }
                }
            } catch (error) {
                this.$emit('message', error)
            } finally {
                this.isAuditing = false
            }
        },
    },

    async created() {
        if (this.payrollResource) {
            this.payrollInstance = this.payrollResource
        } else {
            let instance = this.$props.instanceGetter()
            this.instance = instance
            let token = this.$props.tokenGetter()
            BaseResource.client = new SigmaAPIClient(instance, token)

            if (this.pathId) {
                this.id = parseInt(this.pathId)
            } else if (this.$route.params && this.$route.params.id) {
                this.id = parseInt(this.$route.params.id)
            }

            await this.getPayroll(this.id)
        }
        this.auditPayroll()
    },
})
</script>

<template>
    <div>
        <b-link @click="auditPayroll" :disabled="isAuditing"> <b-icon-arrow-clockwise :animation="isAuditing ? 'spin' : ''"></b-icon-arrow-clockwise> Reload Audit </b-link>
        <ag-grid-vue class="ag-theme-balham default-grid" :grid-options="validationGridOptions" :column-defs="columnDefs" :row-data="rowData" />
    </div>
</template>

<style lang="scss">
@import '../../node_modules/ag-grid-community/dist/styles/ag-grid.css';
@import '../../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css';

.default-grid {
    float: left;
    width: 100%;
    height: 700px;
}
a.disabled {
    pointer-events: none;
    color: #000000;
    opacity: 0.5;
}
.ag-theme-balham .ag-row.AGRowWarning {
    color: #9e4900;
}
.ag-theme-balham .ag-row.AGRowCritical {
    color: #ff0000;
}
</style>
