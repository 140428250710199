<script>
import Vue from 'vue'
import AddressResource from '@sigmacloud/sigma-client/dist/resources/system/address'
import CompanyResource from '@sigmacloud/sigma-client/dist/resources/system/company'
import Autocomplete from './Autocomplete.vue'

export default Vue.extend({
    components: { Autocomplete },
    name: 'CompanyAddress',
    props: {
        payroll: {
            required: false,
            type: Object,
            default: undefined,
        },
    },
    coponents: { Autocomplete },
    data() {
        return {
            company: undefined,
            allCompanies: [],
            allCompanyAddresses: [],
            selectedAddress: undefined,
            selectedRadio: undefined,
            newAddress: undefined,
            selectedAutocompleteAddress: undefined,
            selectRadioOptionAlert: false,
            companyAddressKey: 0,
            otherAddressKey: 0,
        }
    },
    computed: {
        existingAddressOptions() {
            let addressOptions = []
            for (let address of this.allCompanyAddresses) {
                let addressString = ''
                addressString += address.attributes.line1 + ', '
                addressString += address.attributes.line2 + ', '
                addressString += address.managers.city.resource.attributes.name + ', '
                addressString += address.managers.state.resource.attributes.code + ' '
                addressString += address.attributes.postal
                addressOptions.push({ text: addressString, value: address.id })
            }
            return addressOptions
        },
        resourceClasses() {
            return {
                AddressResource,
                CompanyResource,
            }
        },
        returnValue() {
            if (this.selectedRadio === 'company') {
                return this.selectedAddress
            } else if (this.selectedRadio === 'other') {
                return this.selectedAutocompleteAddress.id
            }
            return undefined
        },
    },
    methods: {
        onCancel() {
            this.$emit('cancel')
        },
        onOk() {
            if (this.returnValue && this.selectedRadio) {
                this.$emit('selectedAddress', this.returnValue)
            }
            if (!this.selectedRadio) {
                this.selectRadioOptionAlert = true
            }
        },
        onSelectAddress(event) {
            this.selectedRadio = 'other'
            this.selectedAutocompleteAddress = event
            this.otherAddressKey++
        },
        dismissAlert() {
            this.selectRadioOptionAlert = false
        },
        existingCompanyAddressSelection() {
            this.selectedRadio = 'company'
            this.companyAddressKey++
        },
    },
    async mounted() {
        this.newAddress = new AddressResource()
        let allCompaniesNegotiator = await CompanyResource.list()
        this.allCompanies = allCompaniesNegotiator.resources
        for (const company of this.allCompanies) {
            await company.resolveRelated({ managers: ['address'] })
            let companyAddress = await company.get('address')
            await companyAddress.resolveRelated({ managers: ['city', 'state'] })
            this.allCompanyAddresses.push(companyAddress)
        }
        if (this.payroll && this.payroll.attributes) {
            console.log(this.payroll)
            let payrollProjectResource = await payroll.get('project')
            await payrollProjectResource.resolveRelated({ managers: ['company'] })
            this.company = await payrollProjectResource.get('company')
        }
    },
})
</script>

<template>
    <div>
        <b-alert :show="selectRadioOptionAlert" name="selectOptionAlert" variant="warning" dismissible @dismiss="dismissAlert">Please select an option</b-alert>
        <b-form-group label="Select an address" v-if="newAddress">
            <b-row class="my-2">
                <b-col cols="4"><b-form-radio v-model="selectedRadio" value="company">Existing company address:</b-form-radio></b-col>
                <b-col><b-form-select :key="companyAddressKey" :options="existingAddressOptions" v-model="selectedAddress" @change="existingCompanyAddressSelection"></b-form-select></b-col>
            </b-row>
            <b-row class="my-2">
                <b-col cols="4">
                    <b-form-radio v-model="selectedRadio" value="other">Other address search:</b-form-radio>
                </b-col>
                <b-col>
                    <autocomplete name="address-autocomplete" ref="address-autocomplete" :resource-class="resourceClasses.AddressResource" display-prop="line1" secondary-display-prop="postal" @select="onSelectAddress" :resolve-managers="['city', 'state']">
                        <template slot="item" slot-scope="option">
                            <p v-if="option.item.resource && option.item.resource.attributes.line1">{{ option.item.resource.get('line1') }}</p>
                            <p v-if="option.item.resource && option.item.resource.attributes.line2">{{ option.item.resource.get('line2') }}</p>
                            <span v-if="option.item.resource && option.item.resource.managers.city.resolved">{{ option.item.resource.get('city.name') }}, </span>
                            <span v-if="option.item.resource && option.item.resource.managers.state.resolved">{{ option.item.resource.get('state.code') }} </span>
                            <span v-if="option.item.resource && option.item.resource.attributes.postal">{{ option.item.resource.get('postal') }}</span>
                        </template></autocomplete
                    >
                </b-col>
            </b-row>
        </b-form-group>
        <div class="d-flex justify-content-end"><b-button class="mx-2" @click="onCancel">Cancel</b-button><b-button class="mx-2" variant="primary" @click="onOk">OK</b-button></div>
    </div>
</template>
