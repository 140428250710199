import BaseResource from '@sigmacloud/sigma-client/dist/resources'
import PayrollResource from '@sigmacloud/sigma-client/dist/resources/transactions/pr'
import { AxiosResponse } from 'axios'
import Sigma from '@sigmacloud/sigma-client'

export default class CustomPayrollResource extends PayrollResource {
    static pollInterval: number = 5
    static pollTimeout: number = 100
    calcAttributes: PayrollCalculationAttributes

    // When overriding a method, type signature must be compatible with originally typed method
    async calculate(options: PayrollCalculationOptions = { background: true }, assignments?: []): Promise<AxiosResponse> {
        // if (!this.id || !Sigma.client.token) return Promise.reject('No id')
        const calcEndpoint = CustomPayrollResource.getDetailRoutePath(this.id) + '/calculate_fees'

        if (assignments && assignments.length) {
            options['limit_to_assignments__in'] = assignments
        }

        let result: AxiosResponse = await Sigma.client.post(calcEndpoint, options)
        return result.data
    }

    async saveWithPostedStatusHandling() {
        this.attributes.background = true

        if (this.attributes.status === 'POSTED') {
            const response: any = await this.backgroundSave()

            if (response && response.response && response.response.data) {
                const messages = []

                Object.entries(response.response.data).forEach((entry) => {
                    const fieldName = entry[0]
                    const errors = entry[1]

                    Object.values(errors).forEach((message) => {
                        if (fieldName != 'non_field_errors') {
                            message = fieldName + ': ' + message
                        }
                        if (response.response.data.can_bypass) {
                            this.attributes.bypass_error = true
                            message = message + '. Click "Save" Again if this is expected.'
                        }

                        messages.push(message)
                    })
                })

                if (messages.length) {
                    throw { messages }
                }
            } else if (response && (!response.data || !response.data.post_status_id)) {
                throw {
                    messages: ['If you are attempting to save a payroll to POSTED status, please make sure it is saved to OPEN or AUDITED status first, and the check date is not in the past.'],
                    response,
                }
            }

            if (this.attributes.bypass_error) {
                this.attributes.bypass_error = false
            }
            await this.statusWait(response.data.post_status_id)
        } else {
            await this.save()
        }
    }

    async backgroundSave() {
        const backgroundSaveEndpoint = CustomPayrollResource.getDetailRoutePath(this.id) + '/post'
        let result = await Sigma.client.patch(backgroundSaveEndpoint, this.attributes)
        return result
    }

    /*
    async calculateNew(options: PayrollCalculationOptions = { background: true }): Promise<any> {
        let result = await this.wrap('view').get()
    }
    */

    // Recursive app to wait for a calculation to be completed
    wait(feeCalcStatusId: number, options: { intervalSeconds?: number; pollTimeout?: number; _iter?: number } = {}): Promise<this> {
        const token = Sigma.client.token
        const feeCalcStatusEndpoint = CustomPayrollResource.getDetailRoutePath(this.id) + '/fee_calc_status?id=' + feeCalcStatusId

        return new Promise(async (resolve, reject) => {
            if (!feeCalcStatusId || !this.id || !token) {
                reject(new Error('Improper status id or missing options'))
            }

            let result = await BaseResource.wrap(feeCalcStatusEndpoint).get()
            let data: PayrollCalculationAttributes = result.data as any

            if (data.status === 'DONE') {
                // console.log('Done')
                // console.log(this)
                resolve(this)
            } else if (data.status === 'ERROR') {
                // reject(this)
                reject(new Error(data.message))
            } else {
                let intervalSeconds = options.intervalSeconds || PayrollCalculationResource.pollInterval
                let interval = intervalSeconds * 1000
                let maxPolls = options.pollTimeout || Math.ceil(PayrollCalculationResource.pollTimeout / intervalSeconds)
                options._iter = options._iter || 1

                if (options._iter > maxPolls) {
                    throw new Error('Reached timeout: report status never reached "DONE"')
                } else {
                    // console.log('Checking')
                    let promise = new Promise((otherResolve) => {
                        options._iter++
                        // Increment iteration -- Wait for n seconds then resolve recursively with this.wait()
                        setTimeout(() => otherResolve(this.wait(feeCalcStatusId, options)), interval)
                    })

                    resolve(promise as Promise<this>)
                }
            }
        })
    }
    // wait function for status updates
    statusWait(postStatusId: number, options: { intervalSeconds?: number; pollTimeout?: number; _iter?: number } = {}): Promise<this> {
        const token = Sigma.client.token
        const postStatusEndpoint = CustomPayrollResource.getDetailRoutePath(this.id) + '/post_status?id=' + postStatusId

        return new Promise(async (resolve, reject) => {
            if (!postStatusId || !this.id || !token) {
                reject(new Error('Improper status id or missing options'))
            }

            let result = await BaseResource.wrap(postStatusEndpoint).get()
            let data: PayrollCalculationAttributes = result.data as any

            if (data.status === 'DONE') {
                resolve(this)
            } else if (data.status === 'ERROR') {
                reject(new Error(data.message))
            } else {
                let intervalSeconds = options.intervalSeconds || PayrollCalculationResource.pollInterval
                let interval = intervalSeconds * 1000
                let maxPolls = options.pollTimeout || Math.ceil(PayrollCalculationResource.pollTimeout / intervalSeconds)
                options._iter = options._iter || 1

                if (options._iter > maxPolls) {
                    throw new Error('Reached timeout: status never reached "DONE"')
                } else {
                    // console.log('Checking')
                    let promise = new Promise((otherResolve) => {
                        options._iter++
                        // Increment iteration -- Wait for n seconds then resolve recursively with this.wait()
                        setTimeout(() => otherResolve(this.statusWait(postStatusId, options)), interval)
                    })

                    resolve(promise as Promise<this>)
                }
            }
        })
    }
    // wait function for payroll validation
    validateWait(validateStatusId: number, options: { intervalSeconds?: number; pollTimeout?: number; _iter?: number } = {}): Promise<this> {
        const token = Sigma.client.token
        const validateStatusEndpoint = CustomPayrollResource.getDetailRoutePath(this.id) + '/validate_status?id=' + validateStatusId

        return new Promise(async (resolve, reject) => {
            if (!validateStatusId || !this.id || !token) {
                reject(new Error('Improper status id or missing options'))
            }

            let result = await BaseResource.wrap(validateStatusEndpoint).get()
            let data: PayrollCalculationAttributes = result.data as any

            if (data.status === 'DONE') {
                resolve(this)
            } else if (data.status === 'ERROR') {
                reject(new Error(data.message))
            } else {
                let intervalSeconds = options.intervalSeconds || PayrollCalculationResource.pollInterval
                let interval = intervalSeconds * 1000
                let maxPolls = options.pollTimeout || Math.ceil(PayrollCalculationResource.pollTimeout / intervalSeconds)
                options._iter = options._iter || 1

                if (options._iter > maxPolls) {
                    throw new Error('Reached timeout: status never reached "DONE"')
                } else {
                    // console.log('Checking')
                    let promise = new Promise((otherResolve) => {
                        options._iter++
                        // Increment iteration -- Wait for n seconds then resolve recursively with this.wait()
                        setTimeout(() => otherResolve(this.validateWait(validateStatusId, options)), interval)
                    })

                    resolve(promise as Promise<this>)
                }
            }
        })
    }
}

export class PayrollCalculationResource extends PayrollResource {
    // static endpoint = '/reports/renders'
    static pollInterval: number = 10
    static pollTimeout: number = Number.POSITIVE_INFINITY
    calcAttributes: PayrollCalculationAttributes

    async view() {
        let endpoint = PayrollCalculationResource.getDetailRoutePath(this.id)
        let result = await PayrollCalculationResource.client.get(`${endpoint}/view`)
        return result.data
    }

    /**
     * Returns a promise that resolves with `this` when status === DONE
     * @param timeoutSeconds Timeout in seconds (defaults to `PayrollCalculationResource.pollTimeout`)
     * @param iteration Start at iteration
     */
    wait(options: { feeCalcStatusId?: number; intervalSeconds?: number; pollTimeout?: number; _iter?: number } = {}): Promise<this> {
        return new Promise(async (resolve, reject) => {
            let isDone = () => this.calcAttributes.status === 'DONE'

            if (isDone()) {
                resolve(this)
            } else {
                let intervalSeconds = options.intervalSeconds || PayrollCalculationResource.pollInterval
                let interval = intervalSeconds * 1000
                // let maxPolls = options.pollTimeout || Math.ceil(PayrollCalculationResource.pollTimeout / intervalSeconds)
                options._iter = options._iter || 1

                // temporarily disable timeout
                let maxPolls = Number.POSITIVE_INFINITY

                await this.update()

                if (isDone()) {
                    resolve(this)
                } else if (options._iter > maxPolls) {
                    reject(new Error('Reached timeout: report status never reached "DONE"'))
                } else {
                    let promise = new Promise((otherResolve) => {
                        options._iter++
                        // Increment iteration -- Wait for n seconds then resolve recursively with this.wait()
                        setTimeout(() => otherResolve(this.wait(options)), interval)
                    })

                    resolve(promise as Promise<this>)
                }
            }
        })
    }
}

export interface PayrollCalculationOptions extends Record<string, any> {
    background?: boolean
}

export interface PayrollCalculationAttributes extends Record<string, any> {
    id?: number
    status: 'WAITING' | 'DONE' | 'ERROR'
    message?: string
}

export interface PayrollFeeStatusAttributes extends Record<string, any> {
    fee_calc_status_id: number
}
