import Vue from 'vue'
import BaseResource from '@sigmacloud/sigma-client/dist/resources/base'
import jwt_decode from 'jwt-decode'

export default Vue.extend({
    data() {
        return {
            token: undefined,
        }
    },
    methods: {
        async getUserId() {
            let user = await this.getUser()
            return user.id
        },

        async getUser() {
            let instances = localStorage.getItem('sigmacloud.approvals.instances')
            let recentInstance = localStorage.getItem('sigmacloud.approvals.recentInstance')
            instances = JSON.parse(instances)
            let currentInstance = instances.find((instance) => {
                return instance.instanceUrl === recentInstance
            })
            let decoded = jwt_decode(currentInstance.token)
            let username = decoded.username
            let userResponse = await BaseResource.wrap('/users', { username: username }).get()
            let user = userResponse.data.results[0]
            return user
        },

        async getUserPermissions() {
            const userId = await this.getUserId()
            return await BaseResource.client.get(`/users/${userId}/permissions`)
        },

        async userHasPermission(permission_string) {
            const permissions = await this.getUserPermissions()
            return permissions.data.includes(permission_string)
        },
    },
})
