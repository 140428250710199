
import Vue from 'vue'
import CompanyAddress from './CompanyAddress.vue'
import ReportsMixins from '../mixins/ReportsMixins'

export default Vue.extend({
    name: 'ReportGenerator',
    components: {
        CompanyAddress,
    },
    props: {
        payroll: {
            type: Object,
            required: true,
        },
        companyAddressId: {
            type: Number,
            default: undefined,
        },
    },
    data() {
        return {
            selectedReportOption: null,
            addressModalDisplayed: false,
            reportsOptions: [],
        }
    },
    mixins: [ReportsMixins],
    beforeMount() {
        this.setReportsOptions()
    },
    computed: {
        loading() {
            return this.reportLoading && this.payroll.id === this.loadingId
        },
    },
    methods: {
        setReportsOptions() {
            for (let report of this.reports) {
                if (report.name !== 'Print Paychecks') {
                    this.reportsOptions.push(report.name)
                }
            }
            this.selectedReportOption = this.reportsOptions[0]
        },
        updateAddress(addressId) {
            this.$emit('update:company-address-id', addressId)
            this.addressModalDisplayed = false
        },
        getReportWithAddressOverride() {
            if (this.companyAddressId) {
                this.generateReport({ override_company_address_id: this.companyAddressId })
            } else {
                this.generateReport()
            }
        },
        generateReport(setup = {}) {
            this.getReport({ ...this.payroll, selected: this.selectedReportOption, ...setup })
        },
    },
})
