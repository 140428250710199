import { BaseLineResource } from '@sigmacloud/sigma-client/dist/resources/transactions/lines'
import AccountResource from '@sigmacloud/sigma-client/dist/resources/system/account'
import PayCodeResource from '@sigmacloud/sigma-client/dist/resources/system/paycode'
import FeeResource from '@sigmacloud/sigma-client/dist/resources/system/fee'
import OCCCodeResource from '@sigmacloud/sigma-client/dist/resources/system/occcode'
import AddressResource from '@sigmacloud/sigma-client/dist/resources/system/address'
import AssignmentResource from '@sigmacloud/sigma-client/dist/resources/system/assignment'
import PayrollLineResource from '@sigmacloud/sigma-client/dist/resources/transactions/pr/lines'
import PayrollResource from '@sigmacloud/sigma-client/dist/resources/transactions/pr'
import EmployeeResource from '@sigmacloud/sigma-client/dist/resources/system/employee'

// @todo
//  /lines/prd/accumulation

export default class CustomPayrollDetailLineResource extends BaseLineResource {
    attributes: CustomPayrollDetailLineResourceAttributes
    static endpoint = '/lines/prd'
    static MODEL_NAME = 'payrolldetailline'
    static get related() {
        return Object.assign({}, BaseLineResource.related, {
            transaction_id: PayrollResource, // This may need to go away once PayrollResource.lines is added @todo
            account: AccountResource,
            pay_code: PayCodeResource,
            fee: FeeResource,
            occ_code: OCCCodeResource,
            work_address: AddressResource,
            assignment: AssignmentResource,
            payroll_line: PayrollLineResource,
            referencing_line: CustomPayrollDetailLineResource,
            related_fee: FeeResource,
            employee: EmployeeResource,
        })
    }

    static defaults = Object.assign({}, BaseLineResource.defaults, {
        type: 'PAY',
    })

    isActuals() {
        return this.attributes.type === 'ACTUALFEE' || (['ERTAX', 'ERFEE'].includes(this.attributes.type) && this.attributes.fee_is_actuals)
    }
}

export interface CustomPayrollDetailLineResourceAttributes extends Record<string, any> {
    id?: number
    transaction_id?: any
    transaction_date?: boolean
    ledger_id?: boolean
    account_code?: boolean
    account_type?: boolean
    account_name?: boolean
    amount_adjusted?: boolean
    meta_name?: boolean
    meta_type?: boolean
    meta_data?: any
    time_posted?: boolean
    currency?: boolean
    bank?: boolean
    period_id?: boolean
    replacement_line?: boolean
    fee_name?: boolean
    pay_code_name?: boolean
    assignment_name?: boolean
    status_display?: boolean
    account_display?: boolean
    employee?: boolean
    fee_is_actuals?: boolean
    created?: string
    updated?: string
    amount_decimal: string
    line_number?: number
    description?: string
    asset?: boolean
    other_name?: string
    hourly_unit_rate?: string
    hours_units?: string
    pension_hours_units?: string
    scale_rate_decimal?: string
    work_date?: string
    pay_period_start_date_override?: string
    pay_period_end_date_override?: string
    customer_budget_code?: string
    customer_fringe_code?: string
    customer_hf_code?: string
    customer_episode?: string
    customer_series?: string
    customer_location?: string
    customer_set?: string
    customer_ff1?: string
    customer_ff2?: string
    customer_ff3?: string
    customer_ff4?: string
    type?: 'PAY' | 'OFFSET' | 'EEFEE' | 'ERFEE' | 'ACTUALFEE' | 'ACCRUAL' | 'DEDUCTION' | 'ERTAX' | 'EETAX' | 'GARNISHMENT'
    status?: 'NONE' | 'EDITED' | 'ADDED' | 'DELETED'
    subject_wages_decimal?: string
    subject_hours_units?: string
    trimmed_by_protected_net_decimal?: string
    fee_pre_bump_amount_decimal?: string
    gross_subject_wages_decimal?: string
    gross_wages_decimal?: string
    series?: number | string
    location?: number | string
    set?: number | string
    tax_code?: number | string
    insurance?: number | string
    free_field_1?: number | string
    free_field_2?: number | string
    free_field_3?: number | string
    free_field_4?: number | string
    third_party_vendor?: number | string
    offset_by?: number | string
    recon?: number | string
    account?: number | string
    pay_code?: number | string
    fee?: number | string
    occ_code?: number | string
    work_address?: number | string
    assignment?: number | string
    adjusted_transaction?: number | string
    payroll_line?: number | string
    referencing_line?: number | string
    related_fee?: number | string
}
