<script>
import Vue from 'vue'
import AddressResource from '@sigmacloud/sigma-client/dist/resources/system/address'
import CityResource from '@sigmacloud/sigma-client/dist/resources/system/city'
import ErrorsMixins from '../mixins/ErrorsMixins'

export default Vue.extend({
    data() {
        return {
            addressString: undefined,
        }
    },
    mixins: [ErrorsMixins],
    async mounted() {
        if (this.params.data && this.params.data.attributes) {
            try {
                let addressResource = await AddressResource.detail(this.params.data.attributes.work_address)
                let line1 = addressResource.attributes.line1
                let line2 = addressResource.attributes.line2
                let cityResource = await CityResource.detail(addressResource.attributes.city)
                let city = cityResource.attributes.name
                await addressResource.resolveRelated({ managers: ['state'] })
                let state = await addressResource.get('state.code')
                let postal = addressResource.attributes.postal
                this.addressString = `${line1}, `
                if (line2) this.addressString += `${line2}, `
                this.addressString += `${city}, ${state} ${postal}`
            } catch (error) {
                this.$emit('message', error)
            }
        }
    },
})
</script>

<template>
    <div>
        <span v-if="addressString">{{ addressString }}</span>
    </div>
</template>
