
import Vue from 'vue'
import BaseResource from '@sigmacloud/sigma-client/dist/resources'

export default Vue.extend({
    name: 'GridAutocomplete',

    data() {
        return {
            keyword: '', // The actual input field
            selection: undefined, // The Selected Value

            isSelected: false, // If the user has made a selection
            isError: false, // If the input or selected field is incorrect, show an error
            showList: false, // Show the options list
            highlightedPosition: 0, // Current list item to highlight
            query: '', // Search sent to Rest Resource API
            loading: false,
            resources: [], // Resources retrieved from search

            // Ag-Grid Vue components can't get props this way.
            lineResource: undefined, // Should be a Rest Resource object
            resourceClass: undefined, // Should be a Rest Resource
            name: '', // Name of input field.  Not really necessary, but helpful if we use the form object down the road
            displayProp: 'code', // Properties to display after a selection is made, e.g. name.
            classes: 'form-control', // A list of css classes
            placeholder: '', // Placeholder text for input
            required: false, // To mark this field as required
            readonly: false, // To mark this field as readonly/disabled
            primaryFilter: undefined, // Primary filter to be used with resourceClass, e.g. ?filter=
            filterOptions: {}, // Filter options that are not the primary filter.  Helps with limiting options, e.g. { type__in: 'POSTED', 'CLOSED' }
        }
    },

    watch: {
        loading() {
            this.$emit('loading', this.loading)
        },
    },

    computed: {
        optionMatches(): Array<BaseResource> {
            const keyword = this.keyword.replace(/[|()\[\]\\]/g, '') || undefined

            if (keyword && this.resources) {
                let matches = this.resources.map((resource) => {
                    return {
                        name: (resource.attributes || {}).name,
                        code: (resource.attributes || {}).code,
                        id: resource.id || resource.uuid,
                    }
                })
                // console.log(matches)
                return matches
            } else {
                return []
            }
        },
    },
    methods: {
        getValue(): string {
            if (this.selection) {
                this.lineResource.set(this.params.colDef.field, this.selection.id)
                return this.selection[this.displayProp]
            } else {
                Vue.nextTick(() => {
                    if (this.selection) {
                        this.lineResource.set(this.params.colDef.field, this.selection.id)
                        return this.selection[this.displayProp]
                    } else {
                        this.isError = true
                        return ''
                    }
                })
            }
        },

        isPopup(): boolean {
            return true
        },

        onInput(value: any): void {
            // console.log('onInput: ' + value.target.value);
            if (!value) {
                return
            }

            if (this.keyword.length > 1) this.lookup()
            this.isSelected = false
            this.isError = false
            this.showList = !!this.keyword
        },

        // When leaving the input field, select the highlighted value
        onSelect(): void {
            this.isSelected = true

            const choice = this.optionMatches[this.highlightedPosition]
            // console.log(choice);``

            // Make sure it's a valid entry with a displayable property
            if (!this.keyword && this.required) {
                this.isError = true
            } else if (!this.keyword) {
                // Return empty if no value and tabbing through.
            } else if (choice && this.resources) {
                this.selection = choice
                this.selected = true
            } else if (choice && choice.hasOwnProperty(this.displayProp)) {
                this.selection = choice
                this.selected = true
            } else if (this.selected) {
                // Do nothing if already selected and tabbing through.
            } else {
                this.isError = true
            }

            this.showList = false
            this.params.api.stopEditing()
        },

        onClick(): void {
            this.onSelect()
        },

        onKeyDown(event): void {
            let keyCode = event.which || event.keyCode
            // console.log(keyCode)

            if (keyCode === 13 || keyCode === 9) {
                this.onSelect() // Enter/Return
            } else if (keyCode === 38) {
                this.moveUp() // Up
            } else if (keyCode === 40) {
                this.moveDown() // Down
            } else if (keyCode === 192) {
                // Escape
                this.showList = false
                this.params.api.stopEditing()
            } else {
                // No action for now
            }

            event.stopPropagation()
        },

        // Rest resource lookup
        async lookup(): Promise<void> {
            let ResourceClass = (this.resourceClass as typeof BaseResource) || undefined
            this.loading = true

            if (this.keyword) {
                let query = Object.assign({}, this.filterOptions)
                query.search = this.keyword
                let result = await ResourceClass.filter(query)
                this.resources = result.resources
            } else {
                let result = await ResourceClass.list()
                this.resources = result.resources
            }

            this.loading = false
        },

        // Rest resource get detail
        detailById(id: string, number): Promise<any> {
            let ResourceClass = (this.resourceClass as typeof BaseResource) || undefined

            if (id) {
                return ResourceClass.detail(id)
            } else {
                return new Promise((resolve) => resolve(false))
            }
        },

        // Move the highlighed item index up
        moveUp(): boolean {
            // Only scroll down if not past the last item on the list
            if (this.showList && this.optionMatches.length && this.highlightedPosition > 0) {
                this.highlightedPosition--

                // Else scroll to the bottom
            } else if (this.showList && this.optionMatches.length && this.highlightedPosition <= 0) {
                this.highlightedPosition = this.optionMatches.length - 1
            } else {
                return false
            }

            const element = 'list-items'
            this.$refs[element][this.highlightedPosition].scrollIntoView()
            this.$refs[element][this.highlightedPosition].focus()
            return true
        },

        // Move the highlighed item index down
        moveDown(): boolean {
            // Only scroll down if not past the last item on the list
            if (this.showList && this.optionMatches.length && this.highlightedPosition < this.optionMatches.length - 1) {
                this.highlightedPosition++

                // Else scroll to the top
            } else if (this.showList && this.optionMatches.length) {
                this.highlightedPosition = 0
            } else {
                return false
            }

            const element = 'list-items'
            this.$refs[element][this.highlightedPosition].scrollIntoView()
            this.$refs[element][this.highlightedPosition].focus()
            return true
        },
    },

    created() {
        // console.log('created')
    },

    mounted() {
        // console.log('mounted')
        // console.log(this.params.lineResource)
        if (this.params.charPress && this.keyword === '') {
            this.keyword = this.params.charPress
        }

        this.resourceClass = this.params.resourceClass || BaseResource
        this.lineResource = this.params.data || {}
        this.name = this.params.name || this.params.colDef.field
        Vue.nextTick(() => {
            this.$refs['input'].focus()
        })
    },
})
