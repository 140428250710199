import BaseResource from '@sigmacloud/sigma-client/dist/resources'

export function sortObjectByProperty<T>(objArray: Array<T>, prop: string, sortOrder: number = 1): Array<T> {
    const clone: any = objArray.slice(0)
    const propPath: any = prop.constructor === Array ? prop : prop.split('.')

    clone.sort(function(a: any, b: any) {
        for (let p in propPath) {
            if (a[propPath[p]] && b[propPath[p]]) {
                a = a[propPath[p]]
                b = b[propPath[p]]
            }
        }

        // convert numeric strings to numbers
        isNaN(a) ? a : +a
        isNaN(b) ? b : +b
        return a < b ? -1 * sortOrder : a > b ? 1 * sortOrder : 0
    })
    return clone
}

export function sortResourcesByProperty<T extends BaseResource>(resourceArray: Array<T>, prop: string, sortOrder: number = 1): Array<T> {
    const clone: any = resourceArray.slice(0)

    clone.sort((a: any, b: any) => {
        if (a.attributes[prop] && b.attributes[prop]) {
            a = a.attributes[prop]
            b = b.attributes[prop]
        }

        // convert numeric strings to numbers
        a = isNaN(a) ? a : +a
        b = isNaN(b) ? b : +b
        const result = a < b ? -1 * sortOrder : a > b ? 1 * sortOrder : 0
        return result
    })
    return clone
}
