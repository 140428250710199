<script>
import Vue from 'vue'
import ErrorsMixin from '../mixins/ErrorsMixins'
import jwt_decode from 'jwt-decode'

export default Vue.extend({
    data() {
        return {
            instance: undefined,
            tokenExpires: undefined,
            tokenOrig: undefined,
            username: undefined,
            errors: undefined,
            errorsShown: false,
            currentPage: 1,
            totalRows: undefined,
            perPage: 20,
            selectedError: undefined,
            copySuccessful: false,
            tableKey: 1,
            confirmDeleteAll: false,
            displayFields: [
                'message',
                'name',
                {
                    key: 'timestampRawValue',
                    label: 'Timestamp',
                    formatter: (value) => {
                        return new Date(value)
                    },
                    sortable: true,
                },
                {
                    key: 'config.baseURL',
                    label: 'Base URL',
                },
                {
                    key: 'config.url',
                    label: 'Endpoint',
                },
                {
                    key: 'config.method',
                    label: 'Method',
                },
            ],
        }
    },

    mixins: [ErrorsMixin],

    methods: {
        tokenInfo() {
            let instances = JSON.parse(localStorage.getItem('sigmacloud.approvals.instances'))
            let currentInstance = instances.find((instance) => {
                return instance.instanceUrl === this.instance
            })
            let token = currentInstance.token
            let dec = jwt_decode(token)
            let { username, orig_iat, exp } = dec
            this.tokenExpires = new Date(exp * 1000)
            this.tokenOrig = new Date(orig_iat * 1000)
            this.username = username
        },

        getInstance() {
            if (this.$data.instance) {
                this.instance = this.$data.instance
            } else {
                this.instance = localStorage.getItem('sigmacloud.approvals.recentInstance')
            }
        },

        toggleErrors() {
            this.errorsShown = !this.errorsShown
            if (this.errorsShown) {
                this.errors = this.getErrors()
                this.totalRows = this.errors.length
                this.currentPage = 1
                this.tableKey++
            }
        },

        onSelectError(error) {
            if (error.length) {
                this.selectedError = error
            } else {
                this.selectedError = undefined
                this.formattedSelectedError = ''
            }
        },

        deleteSelectedError() {
            this.deleteError(this.selectedError[0].id)
            this.errors = this.getErrors()
            this.totalRows = this.errors.length
            this.currentPage = 1
            this.tableKey++
        },

        alertConfirmDeleteAll() {
            this.confirmDeleteAll = !this.confirmDeleteAll
        },

        deleteAllErrors() {
            this.confirmDeleteAll = false
            this.clearAllErrors()
            this.errors = this.getErrors()
            this.totalRows = this.errors.length
            this.currentPage = 1
            this.tableKey++
        },

        onCopyError() {
            console.log('click')
            this.copySuccessful = true
            setTimeout(() => (this.copySuccessful = false), 3000)
        },
    },

    mounted() {
        this.getInstance()
        this.errors = this.getErrors()
        this.tokenInfo()
        this.totalRows = this.errors.length
        this.tableKey++
    },
})
</script>

<template>
    <div>
        <h4 class="mt-4">System Information</h4>
        <div class="ml-5 mt-3">
            <p v-if="instance" class="font-weight-bold">
                Current instance: <span class="text-monospace small">{{ instance }}</span>
            </p>
            <p v-if="username" class="font-weight-bold">
                Logged-in as: <span class="text-monospace small">{{ username }}</span>
            </p>
            <p v-if="tokenOrig" class="font-weight-bold">
                Logged-in since: <span class="text-monospace small">{{ tokenOrig }}</span>
            </p>
            <p v-if="tokenExpires" class="font-weight-bold">
                Token Expiration: <span class="text-monospace small">{{ tokenExpires }}</span>
            </p>
        </div>
        <div class="col-sm-10">
            <b-button v-if="!errorsShown" v-b-toggle.errors-collapse @click="toggleErrors">Show errors</b-button>
            <b-button v-if="errorsShown" v-b-toggle.errors-collapse @click="toggleErrors">Hide errors</b-button>
            <b-collapse id="errors-collapse" class="mt-2">
                <b-card v-if="selectedError" class="mb-2">
                    <b-card-text
                        ><pre>{{ selectedError }}</pre></b-card-text
                    >
                    <b-button v-clipboard:copy="JSON.stringify(selectedError)" variant="primary" @click="onCopyError">Copy</b-button>
                    <b-button @click="deleteSelectedError" variant="danger" class="ml-2">Delete Selected</b-button>
                    <b-button class="ml-2" variant="danger" @click="alertConfirmDeleteAll">Delete All</b-button>
                </b-card>
                <b-alert :show="copySuccessful" variant="info">Your error message was successfully copied to your clipboard.</b-alert>
                <b-alert :show="confirmDeleteAll" variant="warning"
                    >Are you sure you want to delete all stored errors? This cannot be undone.
                    <br />
                    <b-button variant="primary" @click="alertConfirmDeleteAll">Cancel</b-button>
                    <b-button variant="danger" class="ml-2" @click="deleteAllErrors">Delete All</b-button>
                </b-alert>
                <b-row>
                    <b-col>
                        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" aria-controls="error-table"></b-pagination>
                        <b-table key="tableKey" name="error-table" v-if="errors" striped hover fixed responsive selectable select-mode="single" :items="errors" :fields="displayFields" :per-page="perPage" :current-page="currentPage" @row-selected="onSelectError"> </b-table>
                    </b-col>
                </b-row>
            </b-collapse>
        </div>
    </div>
</template>
