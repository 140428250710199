
import Vue from 'vue'

/*
 * Import Ag-Grid files and Enterprise License
 */
import { AgGridVue } from 'ag-grid-vue'
import 'ag-grid-enterprise'
import { LicenseManager } from 'ag-grid-enterprise'
LicenseManager.setLicenseKey('CompanyName=Revolution Payroll,LicensedGroup=ProBooks,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-021824,ExpiryDate=4_December_2022_[v2]_MTY3MDExMjAwMDAwMA==0723da76405e8e4969df68bfe317209f')

// Custom Resources
import CustomPayrollDetailLineResource from '../resources/customPayrollDetailLineResource'
import PayrollResource from '../resources/customPayrollResource'
import TimeEdit from './TimeEdit.vue'
import PayrollValidationGrid from './PayrollValidationGrid.vue'
import PayrollReportsTable from './PayrollReportsTable.vue'
import AgGridColumnMixins from '../mixins/AgGridColumns'
import { sortResourcesByProperty } from '../utils/sorting'
import { AssignmentResource, PayCodeResource, ProjectResource } from '@sigmacloud/sigma-client/dist/resources'
import Autocomplete from './Autocomplete.vue'
import AssignmentSelect from './AssignmentSelect.vue'
import UserMixin from '../mixins/UserMixin'
import ErrorsMixins from '../mixins/ErrorsMixins'

export default Vue.extend({
    name: 'PayrollDetailGrid',

    components: {
        AgGridVue,
        TimeEdit,
        Autocomplete,
        AssignmentSelect,
        PayrollValidationGrid,
        PayrollReportsTable,
    },

    mixins: [AgGridColumnMixins, UserMixin, ErrorsMixins],

    props: {
        resources: {
            type: Object,
            required: false,
        },
        payrollResource: {
            type: PayrollResource,
            required: false,
        },
        projectResource: {
            type: ProjectResource,
            required: false,
        },
        calcBusy: {
            type: Boolean,
            required: true,
        },
        saveBusy: {
            type: Boolean,
            required: false,
        },
        hasCalculated: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data() {
        return {
            lineResources: undefined, // PayrollDetailLine Resources that will be our rows
            loading: false,
            lineSaveProgress: 0,
            msg: '',

            // Ag-Grid options
            columnDefs: undefined,
            rowData: undefined,
            gridApi: null,
            columnApi: null,
            groupingLevel: 0,
            groupDefaultExpanded: '2',
            loaded: false,
            scope: this,
            saving: false,
            deleting: false,
            unsavedChanges: false,
            showSaveWarning: false,
            gridOptions: {
                defaultColDef: {
                    resizable: true,
                },
                undoRedoCellEditing: true,
                singleClickEdit: true,
                suppressAggFuncInHeader: true,
                onGridReady: (params) => {
                    this.$data.scope.onGridReady(params)
                },
                onCellEditingStarted: (params) => {
                    this.$data.scope.onCellEditingStarted(params)
                },
                onCellValueChanged: (params) => {
                    this.$data.scope.onCellValueChanged(params)
                },
                rowClassRules: {
                    'ag-employee': (params) => {
                        return params.node.group === true && params.node.level === 0
                    },
                    'ag-group': (params) => {
                        return params.node.group === true && params.node.level === 1
                    },
                    'ag-changed': (params) => {
                        return (params.data || {}).edited || false
                    },
                },
                defaultGroupSortComparator: (nodeA, nodeB) => {
                    if (nodeA.key < nodeB.key) {
                        return -1
                    } else if (nodeA.key > nodeB.key) {
                        return 1
                    } else {
                        return 0
                    }
                },
                autoGroupColumnDef: {
                    headerName: 'Employee',
                    width: 350,
                    cellRendererParams: {
                        checkbox: true,
                        suppressCount: true,
                    },
                    suppressSizeToFit: true,
                },
                isExternalFilterPresent: function() {
                    return true
                },
                doesExternalFilterPass: function(rowNode) {
                    return rowNode.data.attributes.status !== 'DELETED'
                },
            },
            payCodes: undefined,
            gridKey: 0,
            prFee: undefined,
            prFeeAmt: undefined,
            prFeeDate: undefined,
            prFeeBudgetCode: undefined,
            assignmentsLoading: false,
            selectedAssignments: [],
            assignmentResources: [],
            showFeeEditError: false,
        }
    },

    watch: {
        unsavedChanges(newValue) {
            if (newValue === true) {
                setTimeout(() => {
                    this.showSaveWarning = true
                }, 300000)
            } else {
                this.showSaveWarning = false
            }
        },
    },

    computed: {
        saveSpinner() {
            if (!this.saveBusy && !this.saving) {
                return false
            } else {
                return true
            }
        },

        saveDisabled() {
            if (!this.saving && !this.saveBusy && !this.calcBusy && !this.isPayrollPosted) {
                return false
            } else {
                return true
            }
        },

        displaySaveProgress() {
            if ((this.saveBusy || this.saving) && !this.calcBusy) {
                return true
            } else {
                return false
            }
        },

        assignmentsAvailable() {
            if (this.assignmentResources && this.assignmentResources.length) {
                return !this.assignmentsLoading
            } else {
                return false
            }
        },

        payrollReportsTabDisplayed() {
            const reports = this.payrollResource.attributes.reports
            return reports && Object.keys(reports).length > 0
        },

        isPayrollPosted() {
            return this.payrollResource && this.payrollResource.attributes.status === 'POSTED'
        },
    },

    methods: {
        // Handle built-in ag-grid events
        onGridReady(params): void {
            if (params) {
                this.gridApi = params.api
                this.columnApi = params.columnApi
            }
        },

        onCellEditingStarted(params) {
            //If payroll is a voided and credited, User can make changes to client fees only, and if user tries to change anything (deduction, employee withholding, gross wages, etc.) other than a billed fee, the program should prevent the change and give an error message
            if (this.payrollResource.attributes.meta_data && this.payrollResource.attributes.meta_data.void_and_credited && params.data.attributes && params.data.attributes.type && params.data.attributes.type != 'ERFEE') {
                this.showFeeEditError = true
                this.gridOptions.api.stopEditing(true)
                return
            } else {
                this.showFeeEditError = false
            }
        },

        hideFeeEditError() {
            this.showFeeEditError = false
        },

        onCellValueChanged(params): void {
            // Check if any change occurred so we can update the corresponding resource
            if (params.value && params.oldValue === params.newValue) {
                return
            }

            this.unsavedChanges = true

            const rowNode = this.gridApi.getDisplayedRowAtIndex(params.rowIndex)

            // Mark data as changed for css purposes
            params.data.edited = true
            this.redrawCurrentRow(rowNode)

            this.setCellFocus()
        },

        addOverrideColumn() {
            if (this.rowData && this.rowData.length) {
                for (let row of this.rowData) {
                    if (row.attributes.status && row.attributes.status === 'EDITED') {
                        row.set('override', true)
                    } else {
                        row.set('override', false)
                    }
                }
            }
        },

        filterLines() {
            let filteredLines = this.lineResources.filter((line) => {
                if (!line.attributes) return false
                if (line.attributes.status === 'DELETED') return false
                if (line.attributes.type === 'OFFSET') return false
                return true
            })

            this.lineResources = filteredLines
            this.rowData = filteredLines
            this.gridKey++
        },

        async validatePr() {
            this.$emit('onValidate', true)
        },

        async deleteSelected() {
            let selected = this.gridApi.getSelectedNodes()
            let promises = []
            for (let rowNode of selected) {
                for (let leafChild of rowNode.allLeafChildren) {
                    let p1 = new Promise((resolve, reject) => {
                        resolve(leafChild.data.delete())
                    })
                    promises.push(p1)
                }
            }
            this.deleting = true
            Promise.all(promises)
                .then(async () => {
                    this.deleting = false
                    await this.getDetailLines(this.payrollResource.id)
                    this.gridApi.refreshCells()
                    this.gridKey++
                })
                .catch(async (error) => {
                    this.$emit('message', error)
                    this.deleting = false
                    await this.getDetailLines(this.payrollResource.id)
                    this.gridApi.refreshCells()
                    this.gridKey++
                })
        },

        // Payroll related functions
        async getDetailLines(payrollResourceId) {
            if (!payrollResourceId) {
                return Promise.reject('No payroll id given.')
            }

            // Need to get all lines so that ag-grid can aggregate for us
            try {
                this.loading = true
                let lineResources = await CustomPayrollDetailLineResource.all({ query: { transaction__id: payrollResourceId, page_size: 500 } })
                if (lineResources) {
                    this.lineResources = lineResources
                    this.filterLines()
                    this.addOverrideColumn()
                    this.gridKey++
                    // this.rowData = this.lineResources
                    await this.getAssignmentResources()
                    return this.lineResources
                }
            } catch (error) {
                this.$emit('message', error)
            } finally {
                this.loading = false
            }
        },

        async getAssignmentResources() {
            let assignmentSet = new Set()
            for (let line of this.lineResources) {
                assignmentSet.add(line.attributes.assignment)
            }
            try {
                this.assignmentsLoading = true
                assignmentSet.forEach(async (assignment) => {
                    let assignmentResource = await AssignmentResource.detail(assignment as number)
                    await assignmentResource.resolveRelated({ managers: ['employee'] })
                    this.assignmentResources.push(assignmentResource)
                })
            } catch (error) {
                this.$emit('message', error)
            } finally {
                this.assignmentsLoading = false
            }
        },

        async getPayCodes() {
            let payCodes = new Set()
            for (let line of this.lineResources) {
                if (line.attributes.pay_code) {
                    try {
                        await line.resolveAttribute('pay_code')
                        let payCodeResource = await PayCodeResource.detail(line.attributes.pay_code)
                        let factor = await payCodeResource.get('factor')
                        line.set('factor', factor)
                        payCodes.add(parseInt(line.attributes.pay_code))
                    } catch (error) {
                        this.$emit('message', error)
                    }
                } else {
                    line.set('factor', 1.0)
                }
            }

            // console.log(this.lineResources)
            let pcIds = Array.from(payCodes)
            try {
                let payCodeResources = []
                for (let id of pcIds) {
                    let result = await PayCodeResource.detail(id as number)
                    payCodeResources.push(result)
                }
                this.payCodes = payCodeResources
            } catch (error) {
                this.$emit('message', error)
            }
        },

        // Loop through only modified lines and save using the built-in resource save method
        async saveLineResources(calculate) {
            this.saving = true
            if (this.calcBusy) {
                this.saving = false
                return
            }
            if (this.lineResources && this.lineResources.length) {
                this.lineSaveProgress = 0
                this.lineResources.forEach(async (line, index) => {
                    if (Object.keys(line).length && Object.keys(line.changes).length && line.attributes) {
                        let changeKeys = Object.keys(line.changes)
                        if (changeKeys.length === 1 && changeKeys[0] === 'override') {
                            // Adding the override column can make it appear as if there are changes to a line, when there are none
                            return
                        } else if (changeKeys.length === 2 && changeKeys[0] === 'override' && changeKeys[1] === 'factor') {
                            // Same issue as above with adding pay code factors.
                            return
                        }
                        try {
                            let projectId = parseInt(this.payrollResource.attributes.project) || undefined
                            let assignmentResource = undefined
                            if (line && line.managers) {
                                // Setting default work address if none exists on the line
                                let assignmentId = parseInt(line.attributes.assignment)
                                if (assignmentId) {
                                    assignmentResource = await AssignmentResource.detail(assignmentId)
                                }
                            }

                            if (!line.attributes.work_address) {
                                let projectResource = await ProjectResource.detail(projectId)
                                await projectResource.managers.work_addresses.resolve()
                                let projectWorkAddress = parseInt(projectResource.managers.work_addresses.primaryKeys[0])
                                let workAddress = undefined
                                if (projectWorkAddress) {
                                    workAddress = projectWorkAddress
                                } else if (assignmentResource && assignmentResource.attributes.address) {
                                    workAddress = assignmentResource.attributes.address
                                } else {
                                    workAddress = 4
                                }

                                line.set('work_address', workAddress)
                            }

                            if (!line.attributes.occ_code) {
                                if (assignmentResource && assignmentResource.managers) {
                                    await assignmentResource.resolveRelated({ managers: ['occ_code'] })
                                    let occCodeRes = await assignmentResource.get('occ_code')
                                    if (occCodeRes) {
                                        line.set('occ_code', occCodeRes.id)
                                    }
                                }
                            }
                            try {
                                this.saving = true
                                let result = await line.save()
                                this.lineSaveProgress++
                                if (result && index === this.lineResources.length - 1) {
                                    this.saving = false
                                }
                                if (calculate) {
                                    this.$emit('onValidate', true)
                                }
                            } catch (error) {
                                this.saving = false
                                this.$emit('message', error)
                            }
                        } catch (apiError) {
                            this.$emit('message', apiError)
                            this.saving = false
                        } finally {
                            this.saving = false
                        }
                    } else {
                        if (calculate) {
                            this.$emit('onValidate', true)
                        }
                    }
                })
                // this.saving = false
                this.$emit('onSave', true)
                this.unsavedChanges = false
            } else {
                this.$emit('onSave', true)
                this.unsavedChanges = false
            }
            // await this.getDetailLines(this.payrollResource.id)
            this.gridKey++
            this.saving = false
        },

        async onAddEmployeeSave(detailLines: Array<CustomPayrollDetailLineResource>) {
            await this.onAddLines(detailLines)
            this.saveLineResources()
        },

        // Add new employee lines to current lines and redraw rows.
        async onAddLines(detailLines: Array<CustomPayrollDetailLineResource>) {
            this.$refs['add-modal'].hide()

            // Add line numbers
            if (detailLines.length) {
                let maxLineNumber = 0
                if (this.lineResources && this.lineResources.length) {
                    maxLineNumber = parseInt(sortResourcesByProperty(this.lineResources, 'line_number', -1)[0].attributes.line_number)
                }
                const lineCounter = (maxLineNumber < this.lineResources.length ? this.lineResources.length : maxLineNumber) + 1

                for (let i = 0; i < detailLines.length; i++) {
                    detailLines[i].set('line_number', lineCounter + i)
                }

                for (let line of detailLines) {
                    if (line.attributes.pay_code) {
                        try {
                            let payCodeResource = await PayCodeResource.detail(line.attributes.pay_code)
                            let factor = await payCodeResource.get('factor')
                            line.set('factor', factor)
                        } catch (error) {
                            this.$emit('message', error)
                        }
                    }
                }

                this.lineResources = this.lineResources.concat(detailLines)
                this.filterLines()
                this.rowData = this.lineResources

                // this.gridApi.setRowData(this.rowData)
                this.gridKey++

                //  this.saveLineResources() // Un-comment to enable automatic saving.
            }
        },

        async unlockPayroll() {
            this.payrollResource.set('suggested_locked_by', null)
            this.payrollResource.set('suggested_locked_until', null)
            try {
                this.loading = true
                await this.payrollResource.save()
            } catch (error) {
                this.$emit('message', error)
            } finally {
                this.loading = false
                this.$refs['unlock-warning-modal'].hide()
            }
        },

        formatDate(dateString) {
            let dateObj = new Date(dateString)
            return `${dateObj.toLocaleDateString('en-US')} ${dateObj.toLocaleTimeString('en-US')}`
        },

        // No real calculation done here.  Sending an event to parent component to run the calc.
        async calculatePayroll() {
            console.log('calculate payroll')
            try {
                await this.saveLineResources(true)
            } catch (error) {
                this.$emit('message', error)
            }
        },

        selectPrFee(fee) {
            this.prFee = fee
        },

        currencyFormatter(value) {
            return parseFloat(value).toFixed(2)
        },

        saveNewPrFee() {
            let feeLine = new CustomPayrollDetailLineResource()
            feeLine.set('fee', this.prFee.id)
            feeLine.set('amount_decimal', this.prFeeAmt)
            feeLine.set('transaction_id', this.payrollResource.id)
            feeLine.set('transaction', this.payrollResource.id)
            feeLine.set('customer_budget_code', this.prFeeBudgetCode)
            feeLine.set('status', 'ADDED')
            if (this.prFeeDate) {
                feeLine.set('work_date', this.prFeeDate)
            } else {
                feeLine.set('work_date', this.payrollResource.attributes.pay_period_end_date)
            }
            if (this.prFee.attributes.line_type) {
                feeLine.set('type', this.prFee.attributes.line_type)
            } else {
                feeLine.set('type', this.prFee.attributes.type)
            }

            this.onAddLines([feeLine])

            this.prFee = undefined
            this.prFeeAmt = undefined
            this.prFeeDate = undefined
        },

        compareLockDate() {
            if (this.payrollResource && this.payrollResource.attributes.suggested_locked_until) {
                let lockTimestamp = this.payrollResource.attributes.suggested_locked_until
                let lockDateObj = new Date(lockTimestamp)
                let currentDateObj = new Date()

                return lockDateObj.getTime() > currentDateObj.getTime() ? true : false
            }
        },

        selectAssignmentsToCalc() {
            this.$refs['assignment-modal'].show()
        },

        onSelectAssignments(selectedAssignments) {
            this.selectedAssigments = selectedAssignments
            this.$emit('selectAssignments', selectedAssignments)
            this.$refs['assignment-modal'].hide()
        },

        clearAssignmentSelection() {
            this.selectedAssignments = []
            this.$emit('selectAssignments', this.selectedAssignments)
            this.$refs['assignment-modal'].hide()
        },

        hideUnlockModal() {
            this.$refs['unlock-warning-modal'].hide()
        },

        showUnlockWarning() {
            this.$refs['unlock-warning-modal'].show()
        },

        emitError(error) {
            this.$emit('message', error)
        },

        async setCanSeeActuals() {
            try {
                const permissions = await this.getUserPermissions()
                this.canSeeActuals = permissions.data.includes('entry.view_actualsamounts')
            } catch (error) {
                this.$emit('message', error)
            }
        },

        disableGridEditIfPayrollPosted() {
            if (this.isPayrollPosted) {
                this.columnDefs.forEach((columnDef) => {
                    columnDef.editable = false
                })
            }
        },
    },

    beforeMount() {
        // Our grid columns.  Definitions in another file
        this.columnDefs = [
            this.payrollEmployeeColumn,
            this.payrollElementTypeColumn,
            this.payrollElementColumn,
            this.payrollHoursColumn,
            this.payrollPensionHoursColumn,
            this.payrollWorkDateColumn,
            this.payrollRateColumn,
            this.payrollScaleRateColumn,
            this.overrideColumn,
            this.payrollAmountColumn,
            this.payrollPayAmountColumn,
            this.payrollERTaxAmountColumn,
            this.payrollEETaxAmountColumn,
            this.payrollEmployerCostAmountColumn,
            this.payrollEENetAmountColumn,
            this.payrollWorkAddressColumn,
            this.clientAccountColumn,
            this.clientFringeColumn,
            this.clientHandlingFeeColumn,
            this.clientSeriesColumn,
            this.clientLocationColumn,
            this.clientSetColumn,
            this.clientFF1Column,
            this.clientFF2Column,
            this.clientFF3Column,
            this.clientFF4Column,
            this.payrollSubjectWagesColumn,
            this.payrollSubjectHoursColumn,
        ]
    },
    async mounted() {
        if (this.payrollResource && this.payrollResource.id) {
            try {
                await Promise.all([this.getDetailLines(this.payrollResource.id), this.setCanSeeActuals()])
                this.filterLines()

                this.loaded = true
                await this.getPayCodes()
            } catch (error) {
                this.$emit('message', error)
            }
        } else {
            this.rowData = []
        }
        this.disableGridEditIfPayrollPosted()
    },
})
