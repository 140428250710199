<script>
import Vue from 'vue'
import ProjectResource from '@sigmacloud/sigma-client/dist/resources/system/project'
import ErrorsMixins from '../mixins/ErrorsMixins'

export default Vue.extend({
    data() {
        return {
            clientName: undefined,
        }
    },
    mixins: [ErrorsMixins],
    async mounted() {
        try {
            let projectResource = await ProjectResource.detail(this.params.data.project)
            let cname = await projectResource.resolveAttribute('client.name')
            let ccode = await projectResource.resolveAttribute('client.code')
            this.clientName = `${ccode} - ${cname}`
        } catch (error) {
            this.$emit('message', error)
        }
    },
})
</script>

<template>
    <div>
        <span v-if="clientName">{{ clientName }}</span>
    </div>
</template>
